import { Auth } from 'aws-amplify';

export const getAuthToken = async () => {
  const session = await Auth.currentSession();
  // console.log('IdToken有効期限:', new Date(session.getIdToken().getExpiration()).toString());
  const token = session.getIdToken().getJwtToken();
  if (token === '') {
    throw new Error('Failed to get IdToken');
  }
  return token;
};
