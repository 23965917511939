import React, { VFC } from 'react';
import * as Icon from 'react-feather';

import { FieldListItemInputValueRender } from './FieldListItemInputValueRender';
import { useField } from '../../../hooks/useJianDetail';
import { useFieldError } from '../../../hooks/useErrorCheck';
import classNames from 'classnames';

export const FieldListRecursiveRender: VFC<{ fieldId: string }> = ({ fieldId }) => {
  const field = useField(fieldId);
  const errorMessages = useFieldError(fieldId);
  const errors = errorMessages?.error_messages ?? [];
  const warnings = errorMessages?.warning_messages ?? [];

  // 非表示項目を除外
  if (!field || field.displayType === 'HIDDEN') {
    return null;
  }

  return (
    <>
      <div className="flex flex-row mb-2 gap-1">
        <div className="basis-2/3">
          <div className="flex flex-row bg-darkblue_10">
            <div
              className={classNames(
                'basis-4/12 flex items-center justify-center bg-darkblue_100 text-white',
                {
                  'bg-error': errors.length > 0,
                  'bg-warning': warnings.length > 0,
                }
              )}
            >
              <div className={classNames('text-white')}>
                {
                  // インポート可能アイコン
                  field.isImportable ? (
                    <div className="ml-2 text-darkblue_80 rounded-full bg-white p-1">
                      <Icon.Link2 size={16} />
                    </div>
                  ) : null
                }
                {
                  // 必須項目アイコン
                  field.isRequired ? (
                    <div className="ml-2 text-red-400 rounded-full bg-white p-1">
                      <Icon.AlertCircle size={16} />
                    </div>
                  ) : null
                }
              </div>
              <p className="font-bold text-lg pl-2">{field.fieldName}</p>
            </div>
            <div className="basis-8/12">
              <FieldListItemInputValueRender fieldId={fieldId} />
            </div>
          </div>
        </div>

        {/* Error message */}
        <div className="basis-1/3 p-1 flex flex-col gap-1">
          {errors.length > 0 && (
            <div className="flex items-start gap-2 text-error text-sm font-bold">
              <div className="flex items-center gap-1 px-1 border-2 rounded border-error">
                <Icon.AlertCircle size={16} className="min-w-max" />
                <p className="whitespace-nowrap">エラー</p>
              </div>
              <div className="flex flex-col gap-1">
                {errors.map((message, i) => (
                  <div key={`${message}_${i}`}>{message}</div>
                ))}
              </div>
            </div>
          )}
          {warnings.length > 0 && (
            <div className="flex items-start gap-1 text-warning text-sm font-bold">
              <div className="flex items-center gap-1 px-1 basis-1/5 border-2 rounded border-warning">
                <Icon.AlertTriangle size={16} className="min-w-max" />
                <p>警告</p>
              </div>
              <div className="flex flex-col gap-1 w-full">
                {warnings.map((message, i) => (
                  <div key={`${message}_${i}`}>{message}</div>
                ))}
              </div>
            </div>
          )}
        </div>
        {null}
      </div>
      {
        // 選択された値に基づいて子項目をrender
        field.children
          ?.filter((childrenOption) => {
            if (Array.isArray(field.value)) {
              return field.value.includes(childrenOption.choiceValue);
            }
            return field.value === childrenOption.choiceValue;
          })
          .map((childrenOption) =>
            childrenOption.children
              ?.sort((a, b) => a.fieldOrder - b.fieldOrder)
              .map((childField) => {
                return (
                  <div key={childField.fieldId} className="flex flex-row justify-start">
                    <div className="mx-2">
                      <Icon.CornerDownRight size={24} className="stroke-darkblue_80 stroke-2" />
                    </div>
                    <div className="basis-11/12">
                      <FieldListRecursiveRender fieldId={childField.fieldId} />
                    </div>
                  </div>
                );
              })
          )
      }
    </>
  );
};
