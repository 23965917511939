import React from 'react';
import { useFieldMaster } from '../../../../hooks/useFieldMaster';

export const CategoryList: React.VFC<{
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}> = ({ selectedCategory, setSelectedCategory }) => {
  const fieldMaster = useFieldMaster();
  return (
    <div className="w-full h-full py-4 space-y-4">
      <section className="space-y-2">
        <h3 className="font-bold text-darkblue_100 text-xl px-2">出場情報</h3>
        <ul className="w-full h-full">
          {fieldMaster.dispatchInfoCategories.map((category) => (
            <li
              key={category.categoryName}
              className="w-full first:border-t border-b border-darkblue_30"
            >
              <button
                type="button"
                onClick={() => setSelectedCategory(category.categoryName)}
                className={`w-full px-4 py-1 text-left ${
                  category.categoryName === selectedCategory
                    ? 'bg-darkblue_100 hover:bg-darkblue_80 text-white'
                    : 'bg-white hover:bg-darkblue_10 text-darkblue_100'
                }`}
              >
                {category.categoryName}
              </button>
            </li>
          ))}
        </ul>
      </section>
      <section className="space-y-2">
        <h3 className="font-bold text-darkblue_100 text-xl px-2">傷病者情報</h3>
        <ul className="w-full h-full">
          {fieldMaster.patientInfoCategories.map((category) => (
            <li
              key={category.categoryName}
              className="w-full first:border-t border-b border-darkblue_30"
            >
              <button
                type="button"
                onClick={() => setSelectedCategory(category.categoryName)}
                className={`w-full px-4 py-1 text-left ${
                  category.categoryName === selectedCategory
                    ? 'bg-darkblue_100 hover:bg-darkblue_80 text-white'
                    : 'bg-white hover:bg-darkblue_10 text-darkblue_100'
                }`}
              >
                {category.categoryName}
              </button>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};
