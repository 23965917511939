import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';

import { ActionPanelButton } from '../../../components/ActionPanelButton';
import { useSelectedOrCurrentYear } from '../../../hooks/useYears';

export const MoveToPatternListButton = () => {
  const navigate = useNavigate();
  const year = useSelectedOrCurrentYear();
  return (
    <div className="fixed top-0 left-16 ml-4 h-16 flex flex-column items-center z-30">
      <ActionPanelButton
        type="light"
        onClick={() => {
          navigate(`/${year}/csv-pattern`);
        }}
        label={'パターン一覧へ'}
        icon={<Icon.List size={24} />}
      />
    </div>
  );
};
