// ロール（隊員アカウントに割り振る権限セット）に関するロジックを担うHooks
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { memberApiClient } from '../utils/apiClient';
import { components } from '../schema/member-management';

export type OperateLevel = components['schemas']['OperateLevel'];

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const getRoles = async () => {
  const res = await memberApiClient.GET('/roles');
  if (res.error) {
    throw new Error(`Failed to GET /roles \n message: ${res.error.message}`);
  }
  return res.data;
};

const createRole = async (body: components['schemas']['RoleCreateRequest']) => {
  const res = await memberApiClient.POST('/roles', {
    body,
  });
  if (res.error) {
    throw new Error(`Failed to POST /roles \n message: ${res.error.message}`);
  }
  return res.data;
};

const updateRole = async (roleId: string, body: components['schemas']['RoleUpdateRequest']) => {
  const res = await memberApiClient.PATCH(`/roles/{roleId}`, {
    params: { path: { roleId } },
    body,
  });
  if (res.error) {
    throw new Error(`Failed to PATCH /roles/{roleId} \n message: ${res.error.message}`);
  }
  return res.data;
};

const deleteRole = async (roleId: string) => {
  const res = await memberApiClient.DELETE(`/roles/{roleId}`, {
    params: { path: { roleId } },
  });
  if (res.error) {
    throw new Error(`Failed to DELETE /roles/{roleId} \n message: ${res.error.message}`);
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useRoles = () => {
  const { data } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  });

  return data?.roles ?? [];
};

export const useCreateRole = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props: components['schemas']['RoleCreateRequest']) => {
      const promise = createRole(props);
      toast.promise(promise, {
        loading: '新しいロールを作成しています...',
        success: '新しいロールを作成しました',
        error: '新しいロールの作成に失敗しました',
      });
      const res = await promise;
      queryClient.invalidateQueries(['roles']);
      return res;
    },
  });
  return {
    isCreating: mutation.isLoading,
    createRole: mutation.mutateAsync,
  };
};

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      roleId,
      ...props
    }: { roleId: string } & components['schemas']['RoleUpdateRequest']) => {
      const promise = updateRole(roleId, props);
      toast.promise(promise, {
        loading: 'ロールを更新しています...',
        success: 'ロールを更新しました',
        error: 'ロールの更新に失敗しました',
      });
      const res = await promise;
      queryClient.invalidateQueries(['roles']);
      return res;
    },
  });
  return {
    isUpdating: mutation.isLoading,
    updateRole: mutation.mutateAsync,
  };
};

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (roleId: string) => {
      const promise = deleteRole(roleId);
      toast.promise(promise, {
        loading: 'ロールを削除しています...',
        success: 'ロールを削除しました',
        error: 'ロールの削除に失敗しました',
      });
      const res = await promise;
      await queryClient.invalidateQueries(['roles']);
      return res;
    },
  });
  return {
    isDeleting: mutation.isLoading,
    deleteRole: mutation.mutate,
  };
};

export const useRefreshRoles = () => {
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries(['roles']);
};
