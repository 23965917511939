import { useCallback } from 'react';
import { atom, selectorFamily, useRecoilState, useSetRecoilState, DefaultValue } from 'recoil';

import { useFieldMaster } from '../../../hooks/useFieldMaster';

/*
 * Types
 */
export type SearchCondition = {
  id: string; // Reactのkeyに使うためのID
  categoryName: string;
  fieldId: string;
  operator: '' | 'eq' | 'ge' | 'le' | 'contain' | 'startsWith' | 'between';
  value: string[];
};

/*
 * States
 */

export const searchConditionListState = atom<SearchCondition[]>({
  key: 'searchConditionListState',
  default: [
    {
      id: 'initialId',
      categoryName: '',
      fieldId: '',
      operator: '',
      value: [''],
    },
  ],
});

export const searchConditionState = selectorFamily<SearchCondition | undefined, { id: string }>({
  key: 'searchConditionState',
  get:
    ({ id }) =>
    ({ get }) => {
      const searchConditionList = get(searchConditionListState);
      return searchConditionList.find((item) => item.id === id);
    },
  set:
    ({ id }) =>
    ({ set, get }, newValue) => {
      const searchConditionList = get(searchConditionListState);
      const newSearchConditionList = searchConditionList.map((item) => {
        if (item.id === id && newValue !== undefined && !(newValue instanceof DefaultValue)) {
          return newValue;
        }
        return item;
      });
      set(searchConditionListState, newSearchConditionList);
    },
});

/*
 * Hook
 */
export const useSearchCondition = (id: string) => {
  const [searchCondition, setSearchCondition] = useRecoilState(searchConditionState({ id }));
  const setSearchConditionList = useSetRecoilState(searchConditionListState);
  const fieldMaster = useFieldMaster();

  const selectedCategoriesfields = [
    ...fieldMaster.dispatchInfoCategories,
    ...fieldMaster.patientInfoCategories,
  ]
    .filter(({ categoryName }) => categoryName === searchCondition?.categoryName)
    .flatMap((category) => category.fields);

  const selectedField = selectedCategoriesfields?.find(
    (item) => item.fieldId === searchCondition?.fieldId
  );

  // カテゴリー変更
  const setCategory = useCallback(
    (categoryName: string) => {
      setSearchCondition((prev) =>
        prev !== undefined
          ? { ...prev, categoryName, fieldId: '', operator: '', value: [''] }
          : undefined
      );
    },
    [setSearchCondition]
  );

  // フィールド変更
  const setField = useCallback(
    (fieldId: string) => {
      setSearchCondition((prev) => {
        if (prev === undefined) {
          return undefined;
        }
        return {
          ...prev,
          fieldId,
          operator: '',
          value: [''],
        };
      });
    },
    [setSearchCondition]
  );

  // オペレータ変更
  const setOperator = useCallback(
    (operator: string) => {
      switch (operator) {
        case 'eq':
        case 'ge':
        case 'le':
        case 'contain':
        case 'startsWith':
          setSearchCondition((prev) => {
            if (prev === undefined) {
              return undefined;
            }
            if (prev.operator === 'between') {
              return { ...prev, operator, value: [''] };
            }
            return { ...prev, operator };
          });
          break;
        case 'between':
          setSearchCondition((prev) => {
            if (prev === undefined) {
              return undefined;
            }
            return { ...prev, operator, value: ['', ''] };
          });
          break;
        default:
          break;
      }
    },
    [setSearchCondition]
  );

  // 値の変更
  const setValue = useCallback(
    (value: string) => {
      setSearchCondition((prev) => {
        if (prev === undefined) {
          return undefined;
        }
        return { ...prev, value: [value] };
      });
    },
    [setSearchCondition]
  );

  const setValueFrom = useCallback(
    (value: string) => {
      setSearchCondition((prev) => {
        if (prev === undefined) {
          return undefined;
        }
        return { ...prev, value: [value, prev.value[1]] };
      });
    },
    [setSearchCondition]
  );

  const setValueTo = useCallback(
    (value: string) => {
      setSearchCondition((prev) => {
        if (prev === undefined) {
          return undefined;
        }
        return { ...prev, value: [prev.value[0], value] };
      });
    },
    [setSearchCondition]
  );

  const setMultipleValue = useCallback(
    (value: string[]) => {
      setSearchCondition((prev) => {
        if (prev === undefined) {
          return undefined;
        }
        return { ...prev, value };
      });
    },
    [setSearchCondition]
  );

  // 検索条件を削除
  const deleteCondition = useCallback(() => {
    setSearchConditionList((prev) => prev.filter((item) => item.id !== id));
  }, [id, setSearchConditionList]);

  return {
    searchCondition,
    selectedCategoriesfields,
    selectedField,
    setCategory,
    setField,
    setOperator,
    setValue,
    setValueFrom,
    setValueTo,
    setMultipleValue,
    deleteCondition,
  };
};
