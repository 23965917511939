import createClient, { type Middleware } from 'openapi-fetch';
import { paths as DataCrudPaths } from '../schema/data-crud';
import { paths as HospitalPaths } from '../schema/hospital-management';
import { paths as MemberPaths } from '../schema/member-management';
import { paths as ErrorCheckPaths } from '../schema/error-check';
import { getAuthToken } from './getAuthToken';

const middleware: Middleware = {
  async onRequest(req) {
    const token = await getAuthToken();
    req.headers.set('Authorization', `Bearer ${token}`);
    return req;
  },
};

const baseUrl = process.env.REACT_APP_REST_API_ENDPOINT;

const datacrudApiClient = createClient<DataCrudPaths>({ baseUrl });
datacrudApiClient.use(middleware);

const hospitalApiClient = createClient<HospitalPaths>({ baseUrl });
hospitalApiClient.use(middleware);

const memberApiClient = createClient<MemberPaths>({ baseUrl });
memberApiClient.use(middleware);

const errorCheckApiClient = createClient<ErrorCheckPaths>({ baseUrl });
errorCheckApiClient.use(middleware);

export { datacrudApiClient, hospitalApiClient, memberApiClient, errorCheckApiClient };
