import { VFC } from 'react';
import { useSearch } from './hooks/useSearch';
import { JianListTable } from '../../components/JianListTable';

export const SearchResult: VFC = () => {
  const { isSearching, result } = useSearch();

  return isSearching ? (
    <p className="text-darkblue_50">検索中...</p>
  ) : result === undefined ? (
    <p className="text-gray-500">検索条件を指定してください</p>
  ) : result.ids.length === 0 ? (
    <p className="text-red-500">条件に該当する事案がありませんでした</p>
  ) : (
    <JianListTable
      jians={result.ids.map((jian) => ({
        ...jian,
        fields: jian.summaryFields,
        infoType: result.target === 'patientInfo' ? 'patient-info' : 'dispatch-info',
      }))}
    />
  );
};
