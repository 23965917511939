import { Save } from 'react-feather';
import { useEffect } from 'react';
import { useHasChangesToUpload, useFieldsUploadMutation } from '../../../hooks/useJianDetail';
import { ActionPanelButton } from '../../../components/ActionPanelButton';

export const SaveButton = () => {
  const mutation = useFieldsUploadMutation();
  const hasChangesToUpload = useHasChangesToUpload();

  // アプリから離れる際に保存されていない変更がある場合に警告を表示する
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasChangesToUpload) {
        e.preventDefault();
        e.returnValue = '保存されていない変更があります。ページを離れますか？';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasChangesToUpload]);

  return (
    <ActionPanelButton
      type="light"
      onClick={() => mutation.mutate()}
      label="保存"
      icon={<Save size={24} />}
      isDisabled={!hasChangesToUpload}
      isFull
    />
  );
};
