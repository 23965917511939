import { LabeledCheckbox } from '../LabeledCheckbox';
import { CategoryBox } from './CategoryBox';
import { useFieldMaster } from '../../hooks/useFieldMaster';

type Props = {
  checkedFieldIds: string[];
  checkFields: (fieldIds: string[]) => void;
  uncheckFields: (fieldIds: string[]) => void;
  disabled?: boolean;
};
export const FieldCheckboxList: React.VFC<Props> = ({
  checkedFieldIds,
  checkFields,
  uncheckFields,
  disabled,
}) => {
  const fieldMaster = useFieldMaster();

  const dispatchInfoFieldIds = fieldMaster.dispatchInfoCategories.flatMap((category) =>
    category.fields.map((field) => field.fieldId)
  );
  const isAllDispatchInfoFieldsChecked = dispatchInfoFieldIds.every((fieldId) =>
    checkedFieldIds.includes(fieldId)
  );

  const patientInfoFieldIds = fieldMaster.patientInfoCategories.flatMap((category) =>
    category.fields.map((field) => field.fieldId)
  );
  const isAllPatientInfoFieldsChecked = patientInfoFieldIds.every((fieldId) =>
    checkedFieldIds.includes(fieldId)
  );

  return (
    <ul
      className={`w-128 h-80 p-2 border ${
        disabled ? 'border-darkblue_30' : 'border-darkblue_100'
      } overflow-y-scroll`}
    >
      <li>
        <LabeledCheckbox
          label="出場情報"
          fontsize="lg"
          checked={isAllDispatchInfoFieldsChecked}
          disabled={disabled}
          onChange={(v) =>
            v ? checkFields(dispatchInfoFieldIds) : uncheckFields(dispatchInfoFieldIds)
          }
        ></LabeledCheckbox>
        <ul className="ml-4">
          {fieldMaster.dispatchInfoCategories.map((category) => {
            return (
              <li key={category.categoryName} className="mt-1">
                <CategoryBox
                  category={category}
                  checkedFieldIds={checkedFieldIds}
                  disabled={disabled}
                  checkFields={checkFields}
                  uncheckFields={uncheckFields}
                />
              </li>
            );
          })}
        </ul>
      </li>
      <li className="mt-2">
        <LabeledCheckbox
          label="傷病者情報"
          fontsize="lg"
          checked={isAllPatientInfoFieldsChecked}
          disabled={disabled}
          onChange={(v) =>
            v ? checkFields(patientInfoFieldIds) : uncheckFields(patientInfoFieldIds)
          }
        ></LabeledCheckbox>
        <ul className="ml-4">
          {fieldMaster.patientInfoCategories.map((category) => {
            return (
              <li key={category.categoryName} className="mt-1">
                <CategoryBox
                  category={category}
                  checkedFieldIds={checkedFieldIds}
                  disabled={disabled}
                  checkFields={checkFields}
                  uncheckFields={uncheckFields}
                />
              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
};
