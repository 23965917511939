import { VFC } from 'react';
import * as Icon from 'react-feather';

import { useRefreshJianList } from '../../../hooks/useJianList';
import { useCreateJian } from '../../../hooks/useJian';
import { useNavigate } from 'react-router-dom';
import { ActionPanelButton } from '../../../components/ActionPanelButton';

export const JianListActionPanel: VFC = () => {
  const { isCreating, createJian } = useCreateJian();
  const navigate = useNavigate();
  const refresh = useRefreshJianList();

  return (
    <div className="top-16 container mx-auto my-4 p-2 rounded bg-darkblue_10 flex gap-4">
      <ActionPanelButton
        type="dark"
        onClick={() => refresh()}
        label={'更新'}
        icon={<Icon.RefreshCw size={24} />}
      />
      <ActionPanelButton
        type="dark"
        onClick={() => createJian()}
        label={'新規作成'}
        icon={<Icon.FilePlus size={24} />}
        isDisabled={isCreating}
      />
      {/* <CreateNewJianButton /> */}
      <ActionPanelButton
        type="dark"
        onClick={() => {
          navigate('app-jians');
        }}
        label={'事案インポート'}
        icon={<Icon.DownloadCloud size={24} />}
      />
      <div className="flex-1">{/* Spacer */}</div>
      <ActionPanelButton
        type="dark"
        onClick={() => {
          navigate('search');
        }}
        label={'検索'}
        icon={<Icon.Search size={24} />}
      />
    </div>
  );
};
