import { VFC } from 'react';
import { Switch } from '@headlessui/react';
import { CheckSquare, Square } from 'react-feather';

type Props = {
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export const CheckBox: VFC<Props> = ({ className, checked, disabled, onChange }) => {
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      className={`relative p-2 flex justify-center content-center ${
        !disabled ? 'cursor-pointer' : ''
      } ${className}`}
    >
      {({ checked }) => (checked ? <CheckSquare size={24} /> : <Square size={24} />)}
    </Switch>
  );
};
