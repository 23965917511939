import React from 'react';

export const Input: React.VFC<React.ComponentPropsWithoutRef<'input'>> = ({
  className,
  ...props
}) => {
  return (
    <input className={'rounded border-darkblue_100 border-2 px-2 py-1 ' + className} {...props} />
  );
};
