import React, { Suspense, useEffect } from 'react';

import { ActionPanel } from './ActionPanel';
import { Table } from './Table/Table';
import { MoveToJianListButton } from '../../components/MoveToJianListButton';
import { useRefreshAppJians } from '../../hooks/useAppJian';
import { useRefreshJianList } from '../../hooks/useJianList';

export const AppJian: React.VFC = () => {
  const refreshAppJians = useRefreshAppJians();
  const refreshJianList = useRefreshJianList();

  useEffect(() => {
    return () => {
      refreshAppJians();
      refreshJianList();
    };
  }, [refreshAppJians, refreshJianList]);

  return (
    <div className="px-24">
      <MoveToJianListButton />
      <h1 className="text-2xl font-bold text-darkblue_100 mb-4">アプリ事案連携</h1>
      <div className="mb-4">
        <ActionPanel />
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Table />
      </Suspense>
    </div>
  );
};
