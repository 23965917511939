import React, { Suspense, useState } from 'react';

import { EditButton, DeleteButton, ViewTitle, ViewText, ViewBadgeList } from '../Components';
import { useDeleteMember } from '../../../hooks/useMembers';
import { MemberItemEditCard } from './MemberItemEditCard';
import { components } from '../../../schema/member-management';
import { PasswordChangeDialog } from './PasswordChangeDialog';

export const MemberItem = (props: components['schemas']['Member']) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);
  const { isDeleting, deleteMember } = useDeleteMember();

  const handleDelete = async () => {
    if (window.confirm(`${props.memberName}のアカウントを削除しますか？`)) {
      deleteMember(props.memberId);
    }
  };

  if (isEditing) {
    return (
      <Suspense
        fallback={
          <li className="w-full flex flex-col gap-4 p-4 rounded-lg border-2 border-darkblue_70">
            Loading...
          </li>
        }
      >
        <MemberItemEditCard
          memberId={props.memberId}
          initValues={{ ...props, licenseIds: props.licenses.map((license) => license.licenseId) }}
          onSubmit={() => setIsEditing(false)}
          onCancel={() => setIsEditing(false)}
        />
      </Suspense>
    );
  }

  return (
    <li className="w-full flex justify-between items-end p-4 border-b border-darkblue_70">
      <div className="space-y-2">
        <ViewTitle value={props.memberName} />
        <div className="space-y-2 pl-2">
          <div className="flex items-center gap-2">
            <ViewText label="ログインID" value={props.loginId} />
            <ViewText label="隊員コード" value={props.memberCode} />
          </div>
          <div className="flex items-center gap-2">
            <ViewText label="所属" value={props.teamName} />
            <ViewText label="ロール" value={props.roleName} />
          </div>
          <div className="flex items-center gap-2">
            <ViewText label="救命士認定区分" value={props.emtClass} />
          </div>
          <ViewBadgeList
            label="資格認定状況"
            badges={props.licenses.map((license) => ({ name: license.licenseName }))}
          />
        </div>
      </div>
      <div className="flex flex-col items-end gap-2">
        {props.canUpdate && (
          <>
            <EditButton disabled={isDeleting} onClick={() => setIsEditing(true)} />
            <button
              className="w-fit flex items-center gap-2 text-darkblue_100 border border-darkblue_100 hover:bg-darkblue_10 disabled:text-darkblue_50 disabled:border-darkblue_50 disabled:bg-white rounded px-2 py-1"
              disabled={isDeleting}
              onClick={() => setIsPasswordEditing(true)}
            >
              パスワード変更
            </button>
          </>
        )}
        {props.canDelete && <DeleteButton disabled={isDeleting} onClick={handleDelete} />}
      </div>

      <PasswordChangeDialog
        open={isPasswordEditing}
        onClose={() => setIsPasswordEditing(false)}
        memberId={props.memberId}
        loginId={props.loginId}
      />
    </li>
  );
};
