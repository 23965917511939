import { components } from '../schema/hospital-management';

export const decodeEstablishmentCode = (
  establishmentCode: components['schemas']['EstablishmentType']
) => {
  switch (establishmentCode) {
    case 'NATIONAL':
      return '国立';
    case 'PUBLIC':
      return '公立';
    case 'KOUTEKI':
      return '公的';
    case 'PRIVATEHOSPITAL':
      return '私的病院';
    case 'PRIVATECLINIC':
      return '私的診療所';
    case 'SEKKOTSUIN':
      return '接骨院';
    case 'OTHER':
      return 'その他';
    default:
      return '';
  }
};

export const decodeEmergencyCode = (emergencyCode: components['schemas']['EmergencyType']) => {
  switch (emergencyCode) {
    case 'EMERGENCY':
      return '救急告示';
    case 'OTHER':
      return '非告示';
    default:
      return '';
  }
};

export const decodeJurisdictionCode = (jurisdictionCode: components['schemas']['Jurisdiction']) => {
  switch (jurisdictionCode) {
    case 'INSIDE':
      return '管内';
    case 'OUTSIDE':
      return '管外';
    default:
      return '';
  }
};

export const decodeEmbodimentCode = (embodimentCode: components['schemas']['EmbodimentType']) => {
  switch (embodimentCode) {
    case 'INITIAL':
      return '初期医療機関';
    case 'SECONDARY':
      return '二次医療機関';
    case 'TERTIARY':
      return '三次医療機関';
    case 'OTHER':
      return 'その他';
    default:
      return '';
  }
};
