import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCsvPatterns } from '../../../../hooks/useCsvPattern';
import { formatJapaneseDateTimeString } from '../../../../utils/dateUtils';

export const TableBody: React.VFC = () => {
  const csvPatterns = useCsvPatterns();
  return (
    <tbody>
      {csvPatterns.map((csvPattern) => (
        <tr
          key={csvPattern.patternId}
          className="w-full border-b last:border-0 border-darkblue_100 flex flex-nowrap items-center"
        >
          <td className="text-lg w-24 text-left p-4">
            <DetailButton id={csvPattern.patternId} />
          </td>
          <td className="text-lg w-64 p-4 break-words">{csvPattern.patternName}</td>
          <td className="text-lg w-48 text-left p-4 break-words">{csvPattern.createdByName}</td>
          <td className="text-lg w-60 text-left p-4">
            {!Number.isNaN(Date.parse(csvPattern.createdAt))
              ? formatJapaneseDateTimeString(new Date(csvPattern.createdAt))
              : '---'}
          </td>
          <td className="text-lg w-60 text-left p-4">
            {csvPattern.updatedAt != null && !Number.isNaN(Date.parse(csvPattern.updatedAt))
              ? formatJapaneseDateTimeString(new Date(csvPattern.updatedAt))
              : '---'}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const DetailButton: React.VFC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(`${id}`)}
      className="bg-darkblue_10 hover:bg-darkblue_10/50 rounded-md border-2 border-darkblue_100 px-2 py-1"
    >
      <span className="text-sm font-bold">詳細</span>
    </button>
  );
};
