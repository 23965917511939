import React, { useState } from 'react';

import { CancelButton, SubmitButton, InputText } from '../Components';
import { useCreateTeam } from '../../../hooks/useTeams';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

type TeamState = {
  teamName: string;
  teamCode: string;
};

export const NewTeamEditCard: React.VFC<Props> = ({ onSubmit, onCancel }) => {
  const [team, setTeam] = useState<TeamState>({ teamName: '', teamCode: '' });
  const { isCreating, createTeam } = useCreateTeam();

  return (
    <form
      className="w-224 flex flex-col gap-6 p-4 rounded-lg border-2 border-darkblue_70 bg-white"
      onSubmit={async (e) => {
        e.preventDefault();
        createTeam(team);
        onSubmit();
      }}
    >
      <div className="flex gap-8">
        <InputText
          label="隊名"
          required
          value={team.teamName}
          onChangeValue={(teamName) => setTeam({ ...team, teamName })}
        />
        <InputText
          label="隊コード"
          required
          pattern="^[a-zA-Z0-9]{4}$"
          title="半角英数字4文字で入力してください"
          value={team.teamCode}
          onChangeValue={(teamCode) => setTeam({ ...team, teamCode })}
        />
      </div>
      <div className="flex justify-end items-center gap-2">
        <CancelButton disabled={isCreating} onClick={() => onCancel()} />
        <SubmitButton label="新規作成" disabled={isCreating} />
      </div>
    </form>
  );
};
