import React from 'react';
import { RefreshCw } from 'react-feather';

import { useMyTeamOnly, useRefreshAppJians } from '../../hooks/useAppJian';
import { LabeledCheckbox } from '../../components/LabeledCheckbox';
import { ActionPanelButton } from '../../components/ActionPanelButton';

export const ActionPanel: React.VFC = () => {
  const refresh = useRefreshAppJians();
  const { myTeamOnly, toggleMyTeamOnly } = useMyTeamOnly();

  return (
    <div className="w-full rounded bg-darkblue_10 flex items-center gap-2 p-2">
      <ActionPanelButton type="dark" label="更新" icon={<RefreshCw />} onClick={() => refresh()} />
      <LabeledCheckbox
        label="自分の隊のみ表示"
        checked={myTeamOnly}
        onChange={toggleMyTeamOnly}
        fontsize="base"
      />
    </div>
  );
};
