import React, { useState } from 'react';

import { SubmitButton, CancelButton, CheckboxList, InputText, Select } from '../Components';
import { useUpdateMember } from '../../../hooks/useMembers';
import { useTeams } from '../../../hooks/useTeams';
import { useRoles } from '../../../hooks/useRoles';
import { useLicenses } from '../../../hooks/useLicenses';
import { useEmtClasses } from '../../../hooks/useEmtClasses';
import { components } from '../../../schema/member-management';

type Props = {
  memberId: string;
  initValues: Required<components['schemas']['MemberUpdateRequest']>;
  onSubmit: () => void;
  onCancel: () => void;
};

export const MemberItemEditCard: React.VFC<Props> = ({
  memberId,
  initValues,
  onSubmit,
  onCancel,
}) => {
  const [member, setMember] = useState(initValues);
  const { isUpdating, updateMember } = useUpdateMember();
  const teams = useTeams();
  const roles = useRoles();
  const licenses = useLicenses();
  const emtClasses = useEmtClasses();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateMember({ memberId, ...member });
    onSubmit();
  };

  return (
    <li className="w-full flex flex-col gap-4 p-4 rounded-lg border-2 border-darkblue_70">
      <form onSubmit={handleSubmit}>
        <div className="space-y-2">
          <div className="flex gap-16">
            <InputText
              label="氏名"
              required
              value={member.memberName}
              onChangeValue={(memberName) => setMember({ ...member, memberName })}
            />
            <InputText
              label="隊員コード"
              required
              value={member.memberCode}
              onChangeValue={(memberCode) => setMember({ ...member, memberCode })}
            />
          </div>
          <div className="flex gap-16">
            <Select
              label="所属"
              required
              value={member.teamId}
              options={teams.map((team) => ({ value: team.teamId, label: team.teamName }))}
              onChangeValue={(teamId) => setMember({ ...member, teamId })}
            />
            <Select
              label="ロール"
              required
              value={member.roleId}
              options={roles.map((role) => ({ value: role.roleId, label: role.roleName }))}
              onChangeValue={(roleId) => setMember({ ...member, roleId })}
            />
          </div>
          <Select
            label="救命士認定区分"
            required
            value={member.emtClass}
            options={emtClasses.map((emtClass) => ({
              value: emtClass.emtClass,
              label: emtClass.emtClassName,
            }))}
            onChangeValue={(emtClass) => {
              if (
                emtClass === 'PARAMEDIC' ||
                emtClass === 'ASSOCIATE_AMBULANCE_MEMBER' ||
                emtClass === 'OTHER_MEMBER'
              ) {
                setMember({ ...member, emtClass });
              }
            }}
          />
          <CheckboxList
            label="資格認定状況"
            values={member.licenseIds}
            options={licenses.map((license) => ({
              value: license.licenseId,
              label: license.licenseName,
            }))}
            onChangeValues={(licenseIds) => setMember({ ...member, licenseIds })}
          />
        </div>
        <div className="flex justify-end items-center gap-2">
          <CancelButton disabled={isUpdating} onClick={() => onCancel()} />
          <SubmitButton disabled={isUpdating} />
        </div>
      </form>
    </li>
  );
};
