import { VFC } from 'react';

import InputNumber from '../../../../components/InputNumber';

type InputIntProps = {
  value: number | null;
  unit: string | undefined;
  onNewValueSet: (newValue: number | null) => void;
  disabled?: boolean;
};

export const InputInt: VFC<InputIntProps> = ({ value, unit, onNewValueSet, disabled }) => {
  // TODO: limit input to integer
  return (
    <div className="py-1 px-2 flex items-center">
      <InputNumber
        value={value ?? ''}
        step={1}
        className={`font-bold ${
          disabled ? 'text-gray-500' : 'text-gray-900'
        } w-full px-3 py-2 text-left rounded bg-transparent border-transparent`}
        onChange={(e) => {
          if (e.target.value === '') {
            onNewValueSet(null);
          } else {
            onNewValueSet(Number(e.target.value));
          }
        }}
        disabled={disabled}
      />
      {unit !== undefined ? (
        <span className={`grow-0 mr-4 ml-1 ${disabled ? 'text-gray-500' : 'text-gray-900'}`}>
          {unit}
        </span>
      ) : null}
    </div>
  );
};
