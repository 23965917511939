import { useNavigate, useParams } from 'react-router-dom';
import * as Icon from 'react-feather';

import { ActionPanelButton } from './ActionPanelButton';

export const MoveToJianListButton = ({ navigateFn }: { navigateFn?: () => void }) => {
  const navigate = useNavigate();
  const { year } = useParams();

  const onClick = () => {
    if (navigateFn !== undefined) {
      navigateFn();
      return;
    }

    if (year) {
      navigate(`/${year}`);
      return;
    }
    navigate('/');
  };

  return (
    <div className="fixed top-0 left-16 ml-4 h-16 flex flex-column items-center z-30">
      <ActionPanelButton
        type="light"
        onClick={onClick}
        label={'事案一覧へ'}
        icon={<Icon.List size={24} />}
      />
    </div>
  );
};
