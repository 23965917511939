import { useParams } from 'react-router-dom';
import { useDeleteJian } from '../../../hooks/useJian';

export const DeleteButton = () => {
  const { jianId } = useParams<{
    jianId: string;
  }>();
  const { isDeleting, deleteJian } = useDeleteJian(jianId);

  return (
    <button
      onClick={() => deleteJian()}
      className="border border-danger-dark text-danger-dark hover:opacity-80 disabled:opacity-50 px-2 py-1 rounded"
      disabled={isDeleting}
    >
      事案を削除
    </button>
  );
};
