import { components } from '../schema/data-crud';

// Defined in https://www.notion.so/txpmedical/6a5ec87b06c14b879274d97e17d8bb31?pvs=4#912e8a6babd44e458f6c43813d31ea38
export const defaultValue = (fieldType: components['schemas']['FieldType']): '' | null | [] => {
  switch (fieldType) {
    case 'STRING':
      return '';
    case 'DATETIME':
      return null;
    case 'DATE':
      return null;
    case 'SINGLECHOICE':
      return '';
    case 'INT':
      return null;
    case 'FLOAT':
      return null;
    case 'MULTIPLECHOICE':
      return [];
  }
};
