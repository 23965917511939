import { VFC } from 'react';

type ActionPanelSingleSelectProps = {
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  value: string;
  icon: JSX.Element;
};

export const ActionPanelSingleSelect: VFC<ActionPanelSingleSelectProps> = ({
  options,
  onChange,
  value,
  icon,
}) => {
  return (
    <div className="px-4 flex border-none shadow-md shadow-darkblue_40 rounded outline outline-2 outline-darkblue_100 font-bold hover:bg-darkblue_40 bg-white text-darkblue_100 cursor-pointer">
      <div className="self-center">{icon}</div>
      <select
        className="border-none bg-transparent bg-right focus:ring-0 cursor-pointer"
        onChange={(e) => onChange(e.target.value)}
        value={value}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
