import { InputDate } from './InputDate';
import { InputFloat } from './InputFloat';
import { InputInt } from './InputInt';
import { InputMultipleSelect } from './InputMultipleSelect';
import { InputSingleSelect } from './InputSingleSelect';
import { InputTextArea } from './InputTextArea';
import { useField, useSetFieldValue } from '../../../../hooks/useJianDetail';

export const FieldListItemInputValueRender = ({ fieldId }: { fieldId: string }) => {
  const field = useField(fieldId);
  const setValue = useSetFieldValue(fieldId);
  if (field === undefined) return <div>Field State Err</div>;
  const disabled = field.displayType === 'DISPLAY_ONLY';

  switch (field.fieldType) {
    case 'STRING':
      return (
        <InputTextArea
          value={(field.value ?? '') as string}
          onNewValueSet={(value) => setValue(value)}
          fieldId={fieldId}
          disabled={disabled}
        />
      );
    case 'SINGLECHOICE':
      return (
        <InputSingleSelect
          value={(field.value ?? '') as string}
          options={field.options ?? []}
          onChange={(v) => setValue(v)}
          disabled={disabled}
        />
      );
    case 'MULTIPLECHOICE':
      return (
        <InputMultipleSelect
          values={(field.value ?? []) as string[]}
          options={field.options ?? []}
          onChange={(v) => setValue(v)}
          disabled={disabled}
        />
      );
    case 'INT':
      return (
        <InputInt
          value={(field.value ?? null) as number | null}
          unit={field.unit ?? undefined}
          onNewValueSet={(v) => setValue(v)}
          disabled={disabled}
        />
      );
    case 'FLOAT':
      return (
        <InputFloat
          value={(field.value ?? null) as number | null}
          unit={field.unit ?? undefined}
          onNewValueSet={(v) => setValue(v)}
          disabled={disabled}
        />
      );
    case 'DATE':
      return (
        <InputDate
          presicion="day"
          value={(field.value ?? null) as string | null}
          onNewValueSet={(v) => setValue(v)}
          disabled={disabled}
        />
      );
    case 'DATETIME':
      return (
        <InputDate
          presicion={field.categoryName === '時系列情報' ? 'second' : 'minute'}
          value={(field.value ?? null) as string | null}
          onNewValueSet={(v) => setValue(v)}
          disabled={disabled}
        />
      );
    default:
      return <div>FieldType error got {field.fieldType}</div>;
  }
};
