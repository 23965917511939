import React, { useState } from 'react';

import { CancelButton, SubmitButton, InputText } from '../Components';
import { useUpdateTeam } from '../../../hooks/useTeams';

type TeamItemEditCardProps = {
  initValues: { teamId: string; teamName: string; teamCode: string };
  onSubmit: () => void;
  onCancel: () => void;
};

export const TeamItemEditCard: React.VFC<TeamItemEditCardProps> = ({
  initValues,
  onSubmit,
  onCancel,
}) => {
  const [team, setTeam] = useState(initValues);
  const { isUpdating, updateTeam } = useUpdateTeam();

  return (
    <li>
      <form
        className="w-full flex flex-col gap-6 p-4 rounded-lg border-2 border-darkblue_70"
        onSubmit={(e) => {
          e.preventDefault();
          updateTeam(team);
          onSubmit();
        }}
      >
        <div className="flex gap-8">
          <InputText
            label="隊名"
            required
            value={team.teamName}
            onChangeValue={(teamName) => setTeam({ ...team, teamName })}
          />
          <InputText
            label="隊コード"
            required
            pattern="^[a-zA-Z0-9]{4}$"
            title="半角英数字4文字で入力してください"
            value={team.teamCode}
            onChangeValue={(teamCode) => setTeam({ ...team, teamCode })}
          />
        </div>
        <div className="flex justify-end items-center gap-2">
          <CancelButton disabled={isUpdating} onClick={() => onCancel()} />
          <SubmitButton disabled={isUpdating} />
        </div>
      </form>
    </li>
  );
};
