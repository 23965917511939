// CSV出力パターン管理のためのロジックをまとめたHooks
import { useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useFieldMaster } from './useFieldMaster';
import { datacrudApiClient as client } from '../utils/apiClient';
import { components } from '../schema/data-crud';
import { useSelectedOrCurrentYear } from './useYears';

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------

// CSVパターン一覧を取得する
const getCsvPatterns = async (): Promise<components['schemas']['CsvPattern'][]> => {
  const { data, error } = await client.GET('/selective-csv/pattern');

  if (error) {
    throw new Error(`Failed to GET /selective-csv/pattern \n message: ${error.message}`);
  }

  return data;
};

// CSVパターンを作成する
const createCsvPattern = async (
  patternName: string,
  pattern: string[]
): Promise<{ patternId: string }> => {
  const { data, error } = await client.POST('/selective-csv/pattern', {
    body: {
      patternName,
      pattern,
    },
  });

  if (error) {
    throw new Error(`Failed to POST /selective-csv/pattern \n message: ${error.message}`);
  }

  return data;
};

// CSVパターンを更新する
const updateCsvPattern = async (
  patternId: string,
  patternName: string,
  pattern: string[]
): Promise<void> => {
  const { error } = await client.PUT('/selective-csv/pattern/{patternId}', {
    params: {
      path: { patternId },
    },
    body: {
      patternName,
      pattern,
    },
  });

  if (error) {
    throw new Error(
      `Failed to PUT /selective-csv/pattern/${patternId} \n message: ${error.message}`
    );
  }

  return;
};

// CSVパターンを削除する
const deleteCsvPattern = async (patternId: string): Promise<void> => {
  const { error } = await client.DELETE('/selective-csv/pattern/{patternId}', {
    params: {
      path: { patternId },
    },
  });

  if (error) {
    throw new Error(
      `Failed to DELETE /selective-csv/pattern/${patternId} \n message: ${error.message}`
    );
  }

  return;
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------

// CSVパターン一覧をRefreshする
export const useRereshCsvPatterns = () => {
  const queryClient = useQueryClient();
  return () =>
    queryClient.resetQueries({
      queryKey: ['csv-pattern'],
      exact: true,
    });
};

// CSVパターン詳細をRefreshする
export const useRefreshCsvPattern = (patternId: string) => {
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries(['csv-pattern', patternId]);
};

// CSVパターン一覧
export const useCsvPatterns = () => {
  const { data } = useQuery({
    queryKey: ['csv-pattern'],
    queryFn: getCsvPatterns,
  });
  return data ?? [];
};

// CSVパターン詳細
export const useCsvPattern = (patternId: string) => {
  const patterns = useCsvPatterns();
  return patterns.find((pattern) => pattern.patternId === patternId);
};

// CSVパターンを作成する
export const useCreateCsvPattern = () => {
  const refreshPatternList = useRereshCsvPatterns();
  const navigate = useNavigate();
  const year = useSelectedOrCurrentYear();

  return async (): Promise<void> => {
    const promise = createCsvPattern('新規CSVパターン', []);
    toast.promise(promise, {
      loading: '新規CSVパターンを作成中',
      success: 'CSVパターンを作成しました',
      error: 'CSVパターンの作成に失敗しました',
    });

    try {
      const { patternId } = await promise;
      refreshPatternList();
      navigate(`/${year}/csv-pattern/${patternId}`);
    } catch (e) {
      console.error(e);
    }
  };
};

// CSVパターンを更新する
export const useUpdateCsvPattern = (patternId: string) => {
  const refreshPatternList = useRereshCsvPatterns();
  const refreshPattern = useRefreshCsvPattern(patternId);

  return async (patternName: string, pattern: string[]) => {
    const promise = updateCsvPattern(patternId, patternName, pattern);
    toast.promise(promise, {
      loading: 'CSVパターンを更新中',
      success: 'CSVパターンを更新しました',
      error: 'CSVパターンの更新に失敗しました',
    });

    try {
      await promise;
      refreshPatternList();
      refreshPattern();
    } catch (e) {
      console.error(e);
    }
  };
};

// CSVパターンを削除する
export const useDeleteCsvPattern = (patternId: string) => {
  const refreshPatternList = useRereshCsvPatterns();
  const navigate = useNavigate();
  const year = useSelectedOrCurrentYear();

  return async () => {
    if (window.confirm('CSVパターンを削除しますか？')) {
      const promise = deleteCsvPattern(patternId);
      toast.promise(promise, {
        loading: 'CSVパターンを削除中',
        success: 'CSVパターンを削除しました',
        error: 'CSVパターンの削除に失敗しました',
      });

      try {
        await promise;
        refreshPatternList();
        navigate(`/${year}/csv-pattern`);
      } catch (e) {
        console.error(e);
      }
    }
  };
};

// FieldMasterとの互換性をチェックする
export const useCheckCompatibility = (patternId: string) => {
  const csvPattern = useCsvPattern(patternId);
  const fieldMaster = useFieldMaster();
  const fieldIdList = [...fieldMaster.dispatchInfoCategories, ...fieldMaster.patientInfoCategories]
    .flatMap((category) => category.fields)
    .map((field) => field.fieldId);

  return useCallback(() => {
    if (csvPattern === undefined) return true;
    return csvPattern.pattern.every((patternId) => fieldIdList.includes(patternId));
  }, [csvPattern, fieldIdList]);
};
