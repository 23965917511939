import React, { useState } from 'react';

import { EditButton, DeleteButton, ViewTitle, ViewBadgeList } from '../Components';
import { useDeleteRole, type OperateLevel } from '../../../hooks/useRoles';
import { RoleItemEditCard } from './RoleItemEditCard';
import { components } from '../../../schema/member-management';

export const RoleItem: React.VFC<components['schemas']['Role']> = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isDeleting, deleteRole } = useDeleteRole();

  const handleDelete = async () => {
    if (window.confirm(`${props.roleName}のデータを削除しますか？`)) {
      deleteRole(props.roleId);
    }
  };

  if (isEditing) {
    return (
      <RoleItemEditCard
        roleId={props.roleId}
        initValues={props}
        onSubmit={() => setIsEditing(false)}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  return (
    <li className="w-full p-4 border-b border-darkblue_70 space-y-2">
      <div className="flex justify-between">
        <ViewTitle value={props.roleName} />
        <div className="flex items-center gap-2">
          {props.canDelete && <DeleteButton disabled={isDeleting} onClick={handleDelete} />}
          {props.canUpdate && (
            <EditButton disabled={isDeleting} onClick={() => setIsEditing(true)} />
          )}
        </div>
      </div>
      <div className="pl-2 space-y-6">
        <div className="space-y-2">
          <div className="text-base text-darkblue_100 font-bold">事案管理権限</div>
          <ul className="pl-4 space-y-2">
            <ViewBadgeList
              label="自身が作成した事案に対する権限"
              badges={createBadges(props.selfJianOperateLevel, true)}
              emptyText="無し"
            />
            <ViewBadgeList
              label="所属隊の全事案に対する権限"
              badges={createBadges(props.teamJianOperateLevel, false)}
              emptyText="無し"
            />
            <ViewBadgeList
              label="本部内の全事案に対する権限"
              badges={createBadges(props.fdJianOperateLevel, false)}
              emptyText="無し"
            />
          </ul>
        </div>
        <div className="space-y-2">
          <div className="text-base text-darkblue_100 font-bold">ロール管理権限</div>
          <ul className="pl-4 space-y-2">
            <ViewBadgeList
              label="ロールを管理する権限"
              badges={createBadges(props.roleOperateLevel, true)}
              emptyText="無し"
            />
          </ul>
        </div>
        <div className="space-y-2">
          <div className="text-base text-darkblue_100 font-bold">救急隊グループ管理権限</div>
          <ul className="pl-4 space-y-2">
            <ViewBadgeList
              label="所属隊を管理する権限"
              badges={createBadges(props.selfTeamOperateLevel, false)}
              emptyText="無し"
            />
            <ViewBadgeList
              label="本部内の全隊を管理する権限"
              badges={createBadges(props.fdTeamOperateLevel, true)}
              emptyText="無し"
            />
          </ul>
        </div>
        <div className="space-y-2">
          <div className="text-base text-darkblue_100 font-bold">隊員アカウント管理権限</div>
          <ul className="pl-4 space-y-2">
            <ViewBadgeList
              label="自身のアカウントを管理する権限"
              badges={createBadges(props.selfMemberOperateLevel, false)}
              emptyText="無し"
            />
            <ViewBadgeList
              label="所属隊の全アカウントを管理する権限"
              badges={createBadges(props.teamMemberOperateLevel, true)}
              emptyText="無し"
            />
            <ViewBadgeList
              label="本部内の全アカウントを管理する権限"
              badges={createBadges(props.fdMemberOperateLevel, true)}
              emptyText="無し"
            />
          </ul>
        </div>
      </div>
    </li>
  );
};

const createBadges = (operateLevel: OperateLevel, isCreatable: boolean) => {
  switch (operateLevel) {
    case 'ALL_DENY':
      return [];
    case 'CAN_READ':
      return [{ name: '閲覧' }];
    case 'CAN_CREATE':
      if (isCreatable) {
        return [{ name: '閲覧' }, { name: '作成' }];
      } else {
        return [{ name: '閲覧' }];
      }
    case 'CAN_UPDATE':
      if (isCreatable) {
        return [{ name: '閲覧' }, { name: '作成' }, { name: '編集' }];
      } else {
        return [{ name: '閲覧' }, { name: '編集' }];
      }
    case 'CAN_DELETE':
      if (isCreatable) {
        return [
          { name: '閲覧' },
          { name: '作成' },
          { name: '編集' },
          { name: '削除', isDangerColor: true },
        ];
      } else {
        return [{ name: '閲覧' }, { name: '編集' }, { name: '削除' }];
      }
  }
};
