import { VFC } from 'react';
import { OutputMenuButton } from './OutputMenuButton/OutputMenuButton';

type Props = {
  selectedJianIds: string[];
};

export const SelectedJianController: VFC<Props> = ({ selectedJianIds }) => {
  return (
    <div className="bg-darkblue_100 flex flex-row gap-4 items-center px-4 py-1">
      <p className="font-bold text-lg text-white">{selectedJianIds.length + ' 件選択中'}</p>
      <OutputMenuButton selectedJianIds={selectedJianIds} />
    </div>
  );
};
