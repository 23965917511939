import { VFC } from 'react';
import { useCategoryError } from '../../../hooks/useErrorCheck';
import { ErrorBadges } from '../../../components/ErrorBadges';

type Props = {
  categoryName: string;
  isSelected: boolean;
  onSelectCategory: (categoryName: string) => void;
};

export const CategoryButton: VFC<Props> = ({
  categoryName,
  isSelected,
  onSelectCategory: onClick,
}) => {
  const { errorCount, warningCount } = useCategoryError(categoryName);

  return (
    <button
      className={
        'p-4 rounded text-center bg-darkblue_20 disabled:bg-darkblue_100 hover:bg-darkblue_30 text-darkblue_100 disabled:text-white max-lg:p-2'
      }
      disabled={isSelected}
      onClick={() => onClick(categoryName)}
    >
      <div className="flex justify-center relative max-lg:justify-start">
        <span className={'text-xl font-bold'}>{categoryName}</span>
        <div className="absolute right-0">
          <ErrorBadges error={errorCount} warning={warningCount} />
        </div>
      </div>
    </button>
  );
};
