import React, { useState } from 'react';
import { Search } from 'react-feather';
import { isEqual } from 'lodash';

import { useHospitalSearchParams } from '../../../hooks/useHospital';
import { ActionPanelBox } from '../../../components/ActionPanelBox';
import { LabeledCheckbox } from '../../../components/LabeledCheckbox';

export const ActionPanel: React.VFC = () => {
  const { hospitalSearchParams, setHospitalSearchParams } = useHospitalSearchParams();
  const [searchCondition, setSearchCondition] = useState(hospitalSearchParams);

  return (
    <ActionPanelBox>
      <form className="flex flex-col gap-2">
        <LabeledCheckbox
          label="お気に入りのみを表示"
          checked={searchCondition.favoriteOnly ?? false}
          fontsize="base"
          onChange={(value) => setSearchCondition((prev) => ({ ...prev, favoriteOnly: value }))}
        />
        <div className="flex gap-4 flex-wrap">
          <InputText
            label="医療機関名"
            value={searchCondition.hospitalName ?? ''}
            onChange={(value) => setSearchCondition((prev) => ({ ...prev, hospitalName: value }))}
          />
          <InputText
            label="国コード"
            value={searchCondition.organizationCode ?? ''}
            onChange={(value) =>
              setSearchCondition((prev) => ({ ...prev, organizationCode: value }))
            }
          />
          <InputText
            label="医療機関コード"
            value={searchCondition.ownCode ?? ''}
            onChange={(value) => setSearchCondition((prev) => ({ ...prev, ownCode: value }))}
          />
        </div>

        <SearchButton
          onClick={() => setHospitalSearchParams(searchCondition)}
          disabled={isEqual(hospitalSearchParams, searchCondition)}
        />
      </form>
    </ActionPanelBox>
  );
};

const InputText: React.VFC<{ label: string; value: string; onChange: (value: string) => void }> = ({
  label,
  value,
  onChange,
}) => (
  <label className="flex items-center gap-2 min-w-fit">
    <div className="text-sm text-darkblue_70 font-bold">{label}</div>
    <input
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-56 rounded border border-darkblue_70 px-2 py-1 text-darkblue_100"
    />
  </label>
);

const SearchButton: React.VFC<{ onClick: () => void; disabled?: boolean }> = ({
  onClick,
  disabled,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="w-fit h-fit flex items-center gap-2 bg-darkblue_100 hover:bg-darkblue_70 disabled:bg-darkblue_40 rounded px-3 py-2"
    >
      <Search size={24} className="text-white" />
      <span className="text-white font-bold text-lg">検索</span>
    </button>
  );
};
