import React, { Suspense } from 'react';
import { ActionPanel } from './ActionPanel';
import { TeamList } from './TeamList';
import { useMe } from '../../../hooks/useMe';

export const TeamMaster: React.VFC = () => {
  const me = useMe();
  return (
    <div className="w-full h-full space-y-2">
      <ActionPanel />
      <div className="w-full h-full rounded-lg border-2 border-darkblue_100 overflow-y-auto p-4">
        <Suspense fallback={<div>Loading...</div>}>
          {me?.masterPermissions?.canListTeams ? <TeamList /> : <div>Permission Denied</div>}
        </Suspense>
      </div>
    </div>
  );
};
