import { Switch } from '@headlessui/react';
import { CheckBox } from './CheckBox';

type Props = {
  label: string;
  checked: boolean;
  disabled?: boolean;
  fontsize: 'sm' | 'base' | 'lg';
  onChange: (value: boolean) => void;
};

export const LabeledCheckbox: React.VFC<Props> = ({
  label,
  checked,
  disabled,
  fontsize,
  onChange,
}) => {
  return (
    <Switch.Group>
      <div className="flex items-center">
        <CheckBox
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={`w-6 text-darkblue_100 px-1 py-0`}
        />
        <Switch.Label
          className={
            'px-1 text-darkblue_100 ' +
            (!disabled ? 'cursor-pointer ' : '') +
            (fontsize === 'sm' ? 'text-sm' : fontsize === 'lg' ? 'text-lg font-bold' : 'text-base')
          }
        >
          {label}
        </Switch.Label>
      </div>
    </Switch.Group>
  );
};
