import React from 'react';

import { TeamItem } from './TeamItem';
import { useTeams } from '../../../hooks/useTeams';

export const TeamList: React.VFC = () => {
  const teams = useTeams();

  return (
    <ul className="w-full space-y-2">
      {teams.map((team) => (
        <TeamItem
          key={team.teamId}
          teamId={team.teamId}
          teamName={team.teamName}
          teamCode={team.teamCode}
          isEditable={team.canUpdate}
          isDeletable={team.canDelete}
        />
      ))}
    </ul>
  );
};
