type Props = {
  label: string;
  required?: boolean;
  value: string;
  options: { value: string; label: string }[];
  onChangeValue: (value: string) => void;
};

export const Select: React.VFC<Props> = ({ label, required, value, options, onChangeValue }) => {
  return (
    <label className="flex items-center gap-3">
      <div className="min-w-max text-sm text-darkblue_70 font-bold">{label}</div>
      <select
        className="w-60 rounded border border-darkblue_70 px-2 py-1 text-darkblue_100"
        required={required}
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};
