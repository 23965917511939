import { Download } from 'react-feather';
import { Menu } from '@headlessui/react';
import { useParams } from 'react-router-dom';

import { usePdfDownload, usePdfTemplates } from '../../../hooks/Download/usePdfDownload';

export const PdfOutputButton = () => {
  const { jianId } = useParams();
  const templates = usePdfTemplates();
  const downloadPdf = usePdfDownload(jianId ?? '');

  if (!jianId) {
    return null;
  }

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="bg-white hover:bg-white/70 text-darkblue_100 rounded flex items-center gap-x-2 px-2 py-1">
        <Download size={24} />
        <div className="font-bold">PDF出力</div>
      </Menu.Button>
      {templates.length > 0 && (
        <Menu.Items className="absolute right-0 mt-1 w-60 border-2 border-darkblue_100 bg-white rounded p-2 space-y-2">
          {templates.map((template) => (
            <Menu.Item key={template.templateId}>
              {({ active }) => (
                <button
                  className={`${active ? 'text-darkblue_70 underline' : 'text-darkblue_100'}`}
                  onClick={() => {
                    downloadPdf(template.templateId);
                  }}
                >
                  {template.templateName}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      )}
    </Menu>
  );
};
