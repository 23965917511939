import React from 'react';
import { useSearchCondition } from '../../hooks/useSearchCondition';
import { Select } from '../atoms/Select';

type FieldSelectorProps = { id: string };

export const FieldSelector: React.VFC<FieldSelectorProps> = ({ id }) => {
  const { searchCondition, selectedCategoriesfields, setField } = useSearchCondition(id);

  if (selectedCategoriesfields.length === 0) {
    return null;
  }

  return (
    <Select
      name="field"
      value={searchCondition?.fieldId ?? ''}
      onChange={(e) => setField(e.target.value)}
      required
    >
      <option value="" hidden>
        項目を選択
      </option>
      {selectedCategoriesfields.map((field) => (
        <option key={field.fieldId} value={field.fieldId}>
          {field.fieldName}
        </option>
      ))}
    </Select>
  );
};
