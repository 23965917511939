// 救急隊を管理するためのロジックを担うHooks
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { memberApiClient } from '../utils/apiClient';
import { components } from '../schema/member-management';

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const getTeams = async () => {
  const res = await memberApiClient.GET('/teams');
  if (res.error) {
    throw new Error(`Failed to GET /teams \n message: ${res.error.message}`);
  }
  return res.data;
};

const createTeam = async (body: components['schemas']['TeamCreateRequest']) => {
  const res = await memberApiClient.POST('/teams', {
    body,
  });
  if (res.error) {
    throw new Error(`Failed to POST /teams \n message: ${res.error.message}`);
  }
  return res.data;
};

const updateTeam = async (teamId: string, body: components['schemas']['TeamUpdateRequest']) => {
  const res = await memberApiClient.PATCH(`/teams/{teamId}`, {
    params: { path: { teamId } },
    body,
  });
  if (res.error) {
    throw new Error(`Failed to PATCH /teams/{teamId} \n message: ${res.error.message}`);
  }
  return res.data;
};

const deleteTeam = async (teamId: string) => {
  const res = await memberApiClient.DELETE(`/teams/{teamId}`, {
    params: { path: { teamId } },
  });
  if (res.error) {
    throw new Error(`Failed to DELETE /teams/{teamId} \n message: ${res.error.message}`);
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useTeams = () => {
  const { data } = useQuery({
    queryKey: ['teams'],
    queryFn: getTeams,
  });
  return data?.teams ?? [];
};

export const useCreateTeam = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props: components['schemas']['TeamCreateRequest']) => {
      const promise = createTeam(props);
      toast.promise(promise, {
        loading: '新しい隊を作成しています...',
        success: '新しい隊を作成しました。',
        error: '新しい隊の作成に失敗しました。',
      });
      const res = await promise;
      queryClient.invalidateQueries(['teams']);
      return res;
    },
  });
  return {
    isCreating: mutation.isLoading,
    createTeam: mutation.mutateAsync,
  };
};

export const useUpdateTeam = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      teamId,
      ...props
    }: { teamId: string } & components['schemas']['TeamUpdateRequest']) => {
      const promise = updateTeam(teamId, props);
      toast.promise(promise, {
        loading: '隊の情報を更新しています...',
        success: '隊の情報を更新しました。',
        error: '隊の情報の更新に失敗しました。',
      });
      const res = await promise;
      queryClient.invalidateQueries(['teams']);
      return res;
    },
  });
  return {
    isUpdating: mutation.isLoading,
    updateTeam: mutation.mutateAsync,
  };
};

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (teamId: string) => {
      const promise = deleteTeam(teamId);
      toast.promise(promise, {
        loading: '隊を削除しています...',
        success: '隊を削除しました。',
        error: '隊の削除に失敗しました。',
      });
      const res = await promise;
      await queryClient.invalidateQueries(['teams']);
      return res;
    },
  });
  return {
    isDeleting: mutation.isLoading,
    deleteTeam: mutation.mutateAsync,
  };
};

export const useRefreshTeams = () => {
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries(['teams']);
};
