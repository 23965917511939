import React from 'react';

type Props = React.ComponentProps<'select'>;

export const Select = ({ className, ...props }: Props) => (
  <select
    {...props}
    className={`border border-darkblue_80 rounded text-sm text-darkblue_100 py-1.5 ${className}`}
  />
);
