import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Menu } from '@headlessui/react';
import { useAuthenticator } from '@aws-amplify/ui-react';

import { BlurBackground } from '../../BlurBackground';
import { useSelectedOrCurrentYear } from '../../../hooks/useYears';

export const SettingButton = () => {
  const { signOut } = useAuthenticator();
  const year = useSelectedOrCurrentYear();

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={`flex gap-2 px-2 py-1 rounded hover:bg-gray-100/20 text-white shadow border-2 border-white`}
      >
        <Icon.Settings className="self-center hover:animate-spin" size={18} />
        設定
      </Menu.Button>
      <Menu.Items className="absolute flex flex-col items-start gap-2 mt-2 right-0 w-52 p-2 rounded border-2 border-darkblue_70 bg-white text-darkblue_100">
        <Menu.Item>
          <Link to={`/setting`} className="hover:underline">
            設定
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`${year}/csv-pattern`} className="hover:underline">
            CSV出力パターン管理
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to={`/hospital`} className="hover:underline">
            病院管理
          </Link>
        </Menu.Item>
        <Menu.Item>
          <button onClick={signOut} className="hover:underline">
            ログアウト
          </button>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  );
};

export const SettingPanel = ({ onClose }: { onClose: () => void }) => {
  const { signOut } = useAuthenticator();

  return (
    <BlurBackground
      className="right-0 flex justify-center items-center"
      onBackgroundClick={onClose}
    >
      <div>
        <button
          className="rounded border-2 border-darkblue_100 bg-darkblue_10 text-lg font-bold p-1"
          onClick={signOut}
        >
          ログアウト
        </button>
      </div>
    </BlurBackground>
  );
};
