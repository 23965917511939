import React from 'react';
import { Trash2 } from 'react-feather';

type Props = {
  label?: string | undefined;
  disabled?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const DeleteButton: React.VFC<Props> = ({ label, disabled, onClick }) => {
  return (
    <button
      className="w-fit flex items-center gap-2 rounded border border-red-600 text-red-600 hover:bg-red-100 disabled:text-red-300 disabled:border-red-300 disabled:bg-transparent px-2 py-1"
      disabled={disabled}
      onClick={onClick}
    >
      <Trash2 size={20} />
      <span className="min-w-max">{label ?? '削除'}</span>
    </button>
  );
};
