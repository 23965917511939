import * as Icon from 'react-feather';
import { useParams } from 'react-router-dom';

import { useCreatePatientInfo } from '../../../hooks/useJian';
import { ActionPanelButton } from '../../../components/ActionPanelButton';

export const CreatePatientInfoButton = () => {
  const { jianId } = useParams<{ jianId: string }>();
  const createPatientInfo = useCreatePatientInfo(jianId ?? '');

  return (
    <ActionPanelButton
      type="light"
      onClick={() => createPatientInfo()}
      label="傷病者追加"
      icon={<Icon.UserPlus size={24} />}
    />
  );
};
