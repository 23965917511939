import React, { useState } from 'react';

import { SubmitButton, CancelButton, CheckboxList, InputText, Select } from '../Components';
import { useCreateMember } from '../../../hooks/useMembers';
import { useTeams } from '../../../hooks/useTeams';
import { useRoles } from '../../../hooks/useRoles';
import { useLicenses } from '../../../hooks/useLicenses';
import { useEmtClasses } from '../../../hooks/useEmtClasses';
import { escapedSymbolArray, checkPassword } from './utils';
import { components } from '../../../schema/member-management';

type Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

export const NewMemberEditCard: React.VFC<Props> = ({ onSubmit, onCancel }) => {
  const [member, setMember] = useState<Required<components['schemas']['MemberCreateRequest']>>({
    loginId: '',
    password: '',
    memberName: '',
    memberCode: '',
    teamId: '',
    roleId: '',
    licenseIds: [],
    emtClass: 'PARAMEDIC',
  });
  const { isCreating, createMember } = useCreateMember();
  const teams = useTeams();
  const roles = useRoles();
  const licenses = useLicenses();
  const emtClasses = useEmtClasses();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!checkPassword(member.password)) {
      return;
    }
    createMember(member);
    onSubmit();
  };

  return (
    <form
      className="w-224 flex flex-col gap-4 p-4 rounded-lg border-2 border-darkblue_70 bg-white"
      onSubmit={handleSubmit}
    >
      <div className="w-full space-y-3">
        <div className="flex gap-16">
          <InputText
            label="氏名"
            required
            value={member.memberName}
            onChangeValue={(memberName) => setMember({ ...member, memberName })}
          />
          <InputText
            label="隊員コード"
            required
            value={member.memberCode}
            onChangeValue={(memberCode) => setMember({ ...member, memberCode })}
          />
        </div>
        <div className="flex gap-16">
          <Select
            label="所属"
            required
            value={member.teamId}
            options={[
              { value: '', label: '--- 未選択 ---' },
              ...teams.map((team) => ({ value: team.teamId, label: team.teamName })),
            ]}
            onChangeValue={(teamId) => setMember({ ...member, teamId })}
          />
          <Select
            label="ロール"
            required
            value={member.roleId}
            options={[
              { value: '', label: '--- 未選択 ---' },
              ...roles.map((role) => ({ value: role.roleId, label: role.roleName })),
            ]}
            onChangeValue={(roleId) => setMember({ ...member, roleId })}
          />
        </div>
        <InputText
          label="ログインID"
          required
          title="ログインIDは後から変更できません"
          value={member.loginId}
          onChangeValue={(loginId) => setMember({ ...member, loginId })}
        />
        <InputText
          label="パスワード"
          required
          minlength={8}
          pattern={`^[a-zA-Z0-9${escapedSymbolArray.join('')}]*$`}
          autoComplete="off"
          title="数字・英小字・英大字・記号をそれぞれ1つ以上含んだ8字以上の文字列を入力してください"
          value={member.password}
          onChangeValue={(password) => setMember({ ...member, password })}
        />
        <Select
          label="救命士認定区分"
          required
          value={member.emtClass}
          options={[
            { value: '', label: '--- 未選択 ---' },
            ...emtClasses.map((emtClass) => ({
              value: emtClass.emtClass,
              label: emtClass.emtClassName,
            })),
          ]}
          onChangeValue={(emtClass) => {
            if (
              emtClass === 'PARAMEDIC' ||
              emtClass === 'ASSOCIATE_AMBULANCE_MEMBER' ||
              emtClass === 'OTHER_MEMBER'
            ) {
              setMember({ ...member, emtClass });
            }
          }}
        />
        <CheckboxList
          label="資格認定状況"
          values={member.licenseIds}
          options={licenses.map((license) => ({
            value: license.licenseId,
            label: license.licenseName,
          }))}
          onChangeValues={(licenseIds) => setMember({ ...member, licenseIds })}
        />
      </div>
      <div className="flex justify-end items-center gap-2">
        <CancelButton disabled={isCreating} onClick={() => onCancel()} />
        <SubmitButton label="新規作成" disabled={isCreating} />
      </div>
    </form>
  );
};
