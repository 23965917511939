import React from 'react';

export const HeaderCell: React.FC<React.ComponentPropsWithoutRef<'th'>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <th
      className={`sticky top-0 px-1 py-2 bg-white border-b border-darkblue_100 text-sm text-darkblue_100 text-left w-auto ${className}`}
      {...props}
    >
      {children}
    </th>
  );
};
