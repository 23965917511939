type Props = {
  label: string;
  badges: { name: string; isDangerColor?: boolean }[];
  emptyText?: string;
};

export const ViewBadgeList: React.VFC<Props> = ({ label, badges, emptyText }) => {
  return (
    <div className="w-full flex items-center gap-4">
      <div className="w-fit text-sm text-darkblue_70 font-bold">{label}</div>

      {badges.length > 0 ? (
        <ul className="flex flex-wrap items-center gap-1">
          {badges.map((badge) => (
            <li
              key={badge.name}
              className={`w-fit text-sm rounded-sm px-1.5 py-px ${
                badge.isDangerColor ? 'text-red-600 bg-red-100' : 'text-darkblue_100 bg-darkblue_10'
              }`}
            >
              {badge.name}
            </li>
          ))}
        </ul>
      ) : emptyText ? (
        <div className="w-fit text-sm px-1.5 py-px text-darkblue_100">{emptyText}</div>
      ) : null}
    </div>
  );
};
