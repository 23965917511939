import { components } from '../../../../schema/data-crud';

export const pad = (n: number) => {
  return String(n < 10 ? '0' + n : n);
};

export const toDateString = (date: Date) => {
  return (
    date.getUTCFullYear() +
    '-' +
    pad(date.getUTCMonth() + 1) +
    '-' +
    pad(date.getUTCDate()) +
    'T' +
    pad(date.getUTCHours()) +
    ':' +
    pad(date.getUTCMinutes()) +
    ':' +
    pad(date.getUTCSeconds()) +
    'Z'
  );
};

export const createRequestCondition = (
  fieldId: string,
  fieldType: string,
  operator: '' | 'eq' | 'ge' | 'le' | 'contain' | 'startsWith' | 'between',
  value: string[]
): components['schemas']['SearchRequest']['conditions'][number] => {
  if (operator === '') {
    return {
      fieldId,
      operator: 'eq',
      value: value[0],
    };
  }

  if (operator === 'eq') {
    if (fieldType === 'DATETIME' || fieldType === 'DATE') {
      const dateFrom = new Date(value[0]);
      const dateTo = new Date(value[0]);
      if (fieldType === 'DATE') {
        dateFrom.setHours(0);
        dateFrom.setMinutes(0);
        dateTo.setHours(23);
        dateTo.setMinutes(59);
      }
      dateFrom.setSeconds(0);
      dateTo.setSeconds(59);
      return {
        fieldId,
        operator: 'between',
        valueFrom: toDateString(dateFrom),
        valueTo: toDateString(dateTo),
      };
    }

    if (fieldType === 'MULTIPLECHOICE') {
      return {
        fieldId,
        operator,
        value: value,
      };
    }

    return {
      fieldId,
      operator,
      value: value[0],
    };
  }

  if (operator === 'between') {
    if (fieldType === 'DATETIME' || fieldType === 'DATE') {
      const dateFrom = new Date(value[0]);
      const dateTo = new Date(value[1]);
      if (fieldType === 'DATE') {
        dateFrom.setHours(0);
        dateFrom.setMinutes(0);
        dateTo.setHours(23);
        dateTo.setMinutes(59);
      }
      dateFrom.setSeconds(0);
      dateTo.setSeconds(59);
      return {
        fieldId,
        operator,
        valueFrom: toDateString(dateFrom),
        valueTo: toDateString(dateTo),
      };
    }
    return {
      fieldId,
      operator,
      valueFrom: value[0],
      valueTo: value[1],
    };
  }

  if (operator === 'ge') {
    if (fieldType === 'DATETIME' || fieldType === 'DATE') {
      const dateFrom = new Date(value[0]);
      if (fieldType === 'DATE') {
        dateFrom.setHours(0);
        dateFrom.setMinutes(0);
      }
      dateFrom.setSeconds(0);
      return {
        fieldId,
        operator,
        value: toDateString(dateFrom),
      };
    }

    return {
      fieldId,
      operator,
      value: value[0],
    };
  }

  if (operator === 'le') {
    if (fieldType === 'DATETIME' || fieldType === 'DATE') {
      const dateTo = new Date(value[0]);
      if (fieldType === 'DATE') {
        dateTo.setHours(23);
        dateTo.setMinutes(59);
      }
      dateTo.setSeconds(59);
      return {
        fieldId,
        operator,
        value: toDateString(dateTo),
      };
    }

    return {
      fieldId,
      operator,
      value: value[0],
    };
  }

  if (operator === 'contain') {
    if (fieldType === 'MULTIPLECHOICE') {
      return {
        fieldId,
        operator,
        value: value,
      };
    }

    return {
      fieldId,
      operator,
      value: value[0],
    };
  }

  // operator === 'startsWith'
  return {
    fieldId,
    operator,
    value: value[0],
  };
};
