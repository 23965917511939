import { Disclosure } from '@headlessui/react';
import { ChevronDown } from 'react-feather';

import { LabeledCheckbox } from '../LabeledCheckbox';

type Props = {
  category: {
    categoryName: string;
    fields: {
      fieldId: string;
      fieldName: string;
    }[];
  };
  checkedFieldIds: string[];
  disabled?: boolean;
  checkFields: (fields: string[]) => void;
  uncheckFields: (fields: string[]) => void;
};

export const CategoryBox: React.VFC<Props> = ({
  category,
  checkedFieldIds,
  disabled,
  checkFields,
  uncheckFields,
}) => {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="flex items-center justify-between pr-4">
            <div className="flex items-center gap-1">
              <LabeledCheckbox
                label={category.categoryName}
                fontsize="base"
                checked={category.fields.every((field) => checkedFieldIds.includes(field.fieldId))}
                disabled={disabled}
                onChange={(v) =>
                  v
                    ? checkFields(category.fields.map((field) => field.fieldId))
                    : uncheckFields(category.fields.map((field) => field.fieldId))
                }
              />
              <span className={`text-darkblue_60 text-xs`}>
                {category.fields.length +
                  ' 件中 ' +
                  category.fields.filter((field) => checkedFieldIds.includes(field.fieldId))
                    .length +
                  ' 件選択中'}
              </span>
            </div>

            <Disclosure.Button className="flex items-center gap-2 px-2 py-px rounded border border-darkblue_100">
              <span className="text-sm text-darkblue_100">
                {open ? '折りたたむ' : '詳細を表示'}
              </span>
              <ChevronDown size={16} className={open ? 'transform rotate-180' : ''} />
            </Disclosure.Button>
          </div>
          <Disclosure.Panel as="ul" className="ml-4">
            {category.fields.map((field) => {
              return (
                <li key={field.fieldId} className="mt-0.5">
                  <LabeledCheckbox
                    label={field.fieldName}
                    fontsize="sm"
                    checked={checkedFieldIds.includes(field.fieldId)}
                    disabled={disabled}
                    onChange={(v) =>
                      v ? checkFields([field.fieldId]) : uncheckFields([field.fieldId])
                    }
                  />
                </li>
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
