import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ActionPanel } from './ActionPanel';
import { MoveToPatternListButton } from './MoveToPatternListButton';
import { PatternEditor } from './PatternEditor/PatternEditor';
import {
  useCsvPattern,
  useUpdateCsvPattern,
  useCheckCompatibility,
} from '../../../hooks/useCsvPattern';

type LocalCsvPattern = {
  patternName: string;
  pattern: string[];
};

export const CsvPatternDetail: React.VFC = () => {
  const { patternId } = useParams<{ patternId: string }>();
  const remoteCsvPattern = useCsvPattern(patternId ?? '');
  const [localCsvPattern, setLocalCsvPattern] = useState<LocalCsvPattern | undefined>(undefined);
  const checkCompatibility = useCheckCompatibility(patternId ?? '');
  const sumbitUpdatePattern = useUpdateCsvPattern(patternId ?? '');

  if (remoteCsvPattern === undefined) {
    return <div>URLで指定されたパターンIDが無効です</div>;
  }

  const isChanged = localCsvPattern !== undefined;

  const csvPattern =
    localCsvPattern !== undefined
      ? {
          ...remoteCsvPattern,
          ...localCsvPattern,
        }
      : remoteCsvPattern;

  const setPatternName = (patternName: string) => {
    setLocalCsvPattern({ patternName, pattern: csvPattern.pattern });
  };

  const toggleField = (fieldId: string) => {
    if (fieldId === '') {
      console.error('fieldId is empty');
      return;
    }

    if (csvPattern.pattern.includes(fieldId)) {
      // チェックが入っている場合、チェックを外す
      setLocalCsvPattern({
        patternName: csvPattern.patternName,
        pattern: csvPattern.pattern.filter((id) => id !== fieldId),
      });
    } else {
      // チェックが入っていない場合、チェックを入れる
      setLocalCsvPattern({
        patternName: csvPattern.patternName,
        pattern: [...csvPattern.pattern, fieldId],
      });
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sumbitUpdatePattern(csvPattern.patternName, csvPattern.pattern);
      }}
      className="w-full h-full space-y-3"
    >
      <MoveToPatternListButton />
      <ActionPanel
        patternId={csvPattern.patternId}
        patternName={csvPattern.patternName}
        submitDisabled={!isChanged}
      />
      {checkCompatibility() ? (
        <div className="w-full h-full px-6 space-y-3">
          <label className="flex items-center gap-3 min-w-fit">
            <div className="text-sm text-darkblue_70 font-bold">パターン名</div>
            <input
              type="text"
              value={csvPattern.patternName}
              onChange={(e) => setPatternName(e.target.value)}
              className="w-60 rounded border border-darkblue_70 px-2 py-1 text-darkblue_100"
            />
          </label>
          <div className="h-[calc(100%-3rem)] min-w-fit rounded border-2 border-darkblue_100">
            <PatternEditor
              pattern={csvPattern.pattern}
              onToggleField={(fieldId) => toggleField(fieldId)}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-full p-3">
          <p className="text-darkblue_50">このパターンは指定された年度と互換性がありません</p>
        </div>
      )}
    </form>
  );
};
