import React from 'react';
import { ActionPanelBox } from '../../../components/ActionPanelBox';

export const ActionPanel: React.VFC<{
  hospitalName: string;
  isEditing: boolean;
  onSubmit: () => Promise<void>;
}> = ({ hospitalName, isEditing, onSubmit }) => {
  return (
    <ActionPanelBox>
      <h2 className="text-darkblue_100 font-bold text-xl">{hospitalName}</h2>
      <div className="grow">{/* Spacer */}</div>
      <button
        type="button"
        disabled={!isEditing}
        onClick={onSubmit}
        className="text-white font-bold text-lg bg-darkblue_100 disabled:bg-darkblue_30 hover:bg-darkblue_70 rounded px-3 py-1.5"
      >
        保存
      </button>
    </ActionPanelBox>
  );
};
