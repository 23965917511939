import * as Icon from 'react-feather';
import { CreatePatientInfoButton } from './CreatePatientInfoButton';
import { useParams } from 'react-router-dom';
import { useJian } from '../../../hooks/useJian';
import { useNavigateFromJianDetail } from '../../../hooks/useJianDetail';
import { DeletePatientInfoButton } from './DeletePatientInfoButton';
import { ActionPanelSingleSelect } from '../../../components/ActionPanelSingleSelect';
import { ActionPanelButton } from '../../../components/ActionPanelButton';
import { useSelectedOrCurrentYear } from '../../../hooks/useYears';

export const PatientInfo = () => {
  const { jianId, infoTypeId } = useParams<{
    jianId: string;
    infoTypeId: string;
  }>();
  const jian = useJian(jianId);
  const navigate = useNavigateFromJianDetail();
  const year = useSelectedOrCurrentYear();
  const options =
    jian?.patientInfos.map((patientInfo, index) => ({
      label: `傷病者-${index + 1}`,
      value: patientInfo.patientInfoId,
    })) ?? [];

  return (
    <div className="my-auto flex gap-4 basis-5/6">
      {/* middle block */}
      <div className="flex flex-shrink-0 content-center items-center gap-4">
        {jian?.patientInfos && jian.patientInfos.length > 0 ? (
          <ActionPanelSingleSelect
            options={options}
            value={infoTypeId ?? ''}
            onChange={(v) => {
              navigate(`/${year}/jian/${jianId}/patient-info/${v}?categoryName=基本情報`);
            }}
            icon={<Icon.User size={24} />}
          />
        ) : (
          <ActionPanelButton
            type="danger"
            onClick={() => {}}
            label={'傷病者はいません'}
            icon={<Icon.User size={24} />}
            isDisabled
          />
        )}

        <CreatePatientInfoButton />
        <DeletePatientInfoButton />
      </div>
    </div>
  );
};
