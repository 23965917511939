/* -------------------------------------------------------------------------------------------------
 * Data
 * -------------------------------------------------------------------------------------------------*/

// 元号のリストデータ
// - 令和より新しい元号を追加するときは、配列の先頭に追加する
// - 明治より古い元号を追加するときは、配列の末尾に追加する
export const eraList = [
  { name: '令和', beganIn: 2019 },
  { name: '平成', beganIn: 1989 },
  { name: '昭和', beganIn: 1926 },
  { name: '大正', beganIn: 1912 },
  { name: '明治', beganIn: 1868 },
] as const;

export type EraName = typeof eraList[number]['name'];

/* -------------------------------------------------------------------------------------------------
 * Types
 * -------------------------------------------------------------------------------------------------*/

export type Inputs = {
  era: EraName;
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  second: string;
};

/* -------------------------------------------------------------------------------------------------
 * Functions
 * -------------------------------------------------------------------------------------------------*/

// 入力値をチェックし、Dateオブジェクトまたはエラーメッセージを返す
export const validateInputsAndConvertToDate = (
  calenderType: 'Japanese' | 'Western',
  inputs: Inputs
): { ok: true; date: Date } | { ok: false; message: string } => {
  const { era, year, month, day, hour, minute, second } = inputs;

  const westernYear =
    calenderType === 'Japanese'
      ? convertJapaneseYearToYear(era, year)
      : { ok: true as const, year };
  if (westernYear.ok === false) {
    return { ok: false, message: westernYear.message };
  }

  const validatedYear = validateYear(westernYear.year);
  if (validatedYear.ok === false) {
    return { ok: false, message: validatedYear.message };
  }

  const validatedMonth = validateMonth(month);
  if (validatedMonth.ok === false) {
    return { ok: false, message: validatedMonth.message };
  }

  const validatedDay = validateDay(day);
  if (validatedDay.ok === false) {
    return { ok: false, message: validatedDay.message };
  }

  const validatedHour = validateHour(hour);
  if (validatedHour.ok === false) {
    return { ok: false, message: validatedHour.message };
  }

  const validatedMinute = validateMinute(minute);
  if (validatedMinute.ok === false) {
    return { ok: false, message: validatedMinute.message };
  }

  const validatedSecond = validateSecond(second);
  if (validatedSecond.ok === false) {
    return { ok: false, message: validatedSecond.message };
  }

  const date = new Date(
    validatedYear.year,
    validatedMonth.month - 1,
    validatedDay.day,
    validatedHour.hour,
    validatedMinute.minute,
    validatedSecond.second
  );

  if (
    date.getFullYear() !== validatedYear.year ||
    date.getMonth() !== validatedMonth.month - 1 ||
    date.getDate() !== validatedDay.day ||
    date.getHours() !== validatedHour.hour ||
    date.getMinutes() !== validatedMinute.minute ||
    date.getSeconds() !== validatedSecond.second
  ) {
    return { ok: false, message: '無効な日時が入力されています' };
  }

  return { ok: true, date };
};

// 年の入力値をチェック
export const validateYear = (
  yearStr: string
): { ok: true; year: number } | { ok: false; message: string } => {
  if (yearStr === '') {
    return { ok: false, message: '「年」を入力してください' };
  }

  const year = Number(yearStr);

  if (isNaN(year)) {
    return { ok: false, message: '「年」には数値を入力してください' };
  }

  if (!Number.isInteger(year)) {
    return { ok: false, message: '「年」には整数を入力してください' };
  }

  if (year < 1) {
    return { ok: false, message: '「年」には1以上の整数を入力してください' };
  }

  const minYear = eraList[eraList.length - 1].beganIn;
  if (year < minYear) {
    return { ok: false, message: `${minYear}年以前のデータは入力できません` };
  }

  return { ok: true, year };
};

// 月の入力値をチェック
export const validateMonth = (
  monthStr: string
): { ok: true; month: number } | { ok: false; message: string } => {
  if (monthStr === '') {
    return { ok: false, message: '「月」を入力してください' };
  }

  const month = Number(monthStr);

  if (isNaN(month)) {
    return { ok: false, message: '「月」には数値を入力してください' };
  }

  if (!Number.isInteger(month)) {
    return { ok: false, message: '「月」には整数を入力してください' };
  }

  if (month < 1 || month > 12) {
    return { ok: false, message: '「月」には1〜12の整数を入力してください' };
  }

  return { ok: true, month };
};

// 日の入力値をチェック
export const validateDay = (
  dayStr: string
): { ok: true; day: number } | { ok: false; message: string } => {
  if (dayStr === '') {
    return { ok: false, message: '「日」を入力してください' };
  }

  const day = Number(dayStr);

  if (isNaN(day)) {
    return { ok: false, message: '「日」には数値を入力してください' };
  }

  if (!Number.isInteger(day)) {
    return { ok: false, message: '「日」には整数を入力してください' };
  }

  if (day < 1 || day > 31) {
    return { ok: false, message: '「日」には1〜31の整数を入力してください' };
  }

  return { ok: true, day };
};

// 時の入力値をチェック
export const validateHour = (
  hourStr: string
): { ok: true; hour: number } | { ok: false; message: string } => {
  if (hourStr === '') {
    return { ok: false, message: '「時」を入力してください' };
  }

  const hour = Number(hourStr);

  if (isNaN(hour)) {
    return { ok: false, message: '「時」には数値を入力してください' };
  }

  if (!Number.isInteger(hour)) {
    return { ok: false, message: '「時」には整数を入力してください' };
  }

  if (hour < 0 || hour > 23) {
    return { ok: false, message: '「時」には0〜23の整数を入力してください' };
  }

  return { ok: true, hour };
};

// 分の入力値をチェック
export const validateMinute = (
  minuteStr: string
): { ok: true; minute: number } | { ok: false; message: string } => {
  if (minuteStr === '') {
    return { ok: false, message: '「分」を入力してください' };
  }

  const minute = Number(minuteStr);

  if (isNaN(minute)) {
    return { ok: false, message: '「分」には数値を入力してください' };
  }

  if (!Number.isInteger(minute)) {
    return { ok: false, message: '「分」には整数を入力してください' };
  }

  if (minute < 0 || minute > 59) {
    return { ok: false, message: '「分」には0〜59の整数を入力してください' };
  }

  return { ok: true, minute };
};

// 秒の入力値をチェック
export const validateSecond = (
  secondStr: string
): { ok: true; second: number } | { ok: false; message: string } => {
  if (secondStr === '') {
    return { ok: false, message: '「秒」を入力してください' };
  }

  const second = Number(secondStr);

  if (isNaN(second)) {
    return { ok: false, message: '「秒」には数値を入力してください' };
  }

  if (!Number.isInteger(second)) {
    return { ok: false, message: '「秒」には整数を入力してください' };
  }

  if (second < 0 || second > 59) {
    return { ok: false, message: '「秒」には0〜59の整数を入力してください' };
  }

  return { ok: true, second };
};

// 和暦から西暦へ変換(年)
export const convertJapaneseYearToYear = (
  era: EraName,
  yearStr: string
): { ok: true; year: string } | { ok: false; message: string } => {
  if (yearStr === '') {
    return { ok: true, year: '' };
  }

  const year = Number(yearStr);

  if (isNaN(year)) {
    return { ok: false, message: '「年」には数値を入力してください' };
  }

  if (!Number.isInteger(year)) {
    return { ok: false, message: '「年」には整数を入力してください' };
  }

  if (year < 1) {
    return { ok: false, message: '「年」には1以上の整数を入力してください' };
  }

  const eraData = eraList.find((e) => era === e.name);
  if (eraData === undefined) {
    return {
      ok: false,
      message: `元号は${eraList.map((e) => e.name).join('、')}のいずれかを指定してください`,
    };
  }

  return { ok: true, year: String(year + eraData.beganIn - 1) };
};

// 西暦から和暦へ変換(年)
export const convertYearToJapaneseYear = (
  yearStr: string
):
  | { ok: true; era: typeof eraList[number]['name']; year: string }
  | { ok: false; message: string } => {
  if (yearStr === '') {
    return { ok: true, era: '令和', year: '' };
  }

  const validatedYear = validateYear(yearStr);
  if (validatedYear.ok === false) {
    return { ok: false, message: validatedYear.message };
  }

  for (const era of eraList) {
    if (validatedYear.year >= era.beganIn) {
      return { ok: true, era: era.name, year: String(validatedYear.year - era.beganIn + 1) };
    }
  }

  console.error('Function Error: something wrong in convertYearToJapaneseYear');
  return { ok: false, message: '不明なエラーが発生しました' };
};
