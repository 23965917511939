import { VFC } from 'react';
import { uniq as _uniq, without as _without, indexOf as _indexOf } from 'lodash';
import * as Icon from 'react-feather';

type InputMultipleSelectProps = {
  options: string[];
  values: string[];
  onChange: (newValue: string[]) => void;
  disabled?: boolean;
};

export const InputMultipleSelect: VFC<InputMultipleSelectProps> = ({
  options,
  values,
  onChange,
  disabled,
}) => {
  const onToggle = (option: string, isSelected: boolean) => {
    if (isSelected) {
      // add selected option to value and update
      onChange(_uniq([...values, option]));
    } else {
      // remove unselected option from value and update
      onChange(_without(values, option));
    }
  };
  return (
    <div className="py-2 px-2 flex flex-wrap justify-start gap-x-4 gap-y-2">
      {options.map((option) => {
        return (
          <MultipleSelectionItem
            key={option}
            option={option}
            isSelected={_indexOf(values, option) >= 0}
            onToggle={onToggle}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

type MultipleSelectionItemProps = {
  option: string;
  isSelected: boolean;
  onToggle: (option: string, isSelected: boolean) => void;
  disabled?: boolean;
};

const MultipleSelectionItem: VFC<MultipleSelectionItemProps> = ({
  option,
  isSelected,
  onToggle,
  disabled,
}) => {
  const onClick = disabled ? () => {} : () => onToggle(option, !isSelected);
  return (
    <button
      className={`flex flex-row px-2 py-1 gap-x-1 rounded border-2 ${
        !disabled && isSelected
          ? 'bg-darkblue_30 border-darkblue_100'
          : 'bg-darkblue_20 border-darkblue_40'
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="self-center">
        {isSelected ? (
          <Icon.CheckSquare
            size={20}
            className={`${disabled ? 'text-darkblue_40' : 'text-darkblue_100'}`}
          />
        ) : (
          <Icon.Square size={20} className="text-darkblue_40" />
        )}
      </div>
      <div className={`font-bold ${disabled ? 'text-gray-500' : 'text-gray-700'}`}>{option}</div>
    </button>
  );
};
