import React, { Suspense } from 'react';

import { ActionPanel } from './ActionPanel';
import { Table } from './Table/Table';
import { MoveToJianListButton } from '../../../components/MoveToJianListButton';

export const HospitalList: React.VFC = () => {
  return (
    <div className="w-full h-full space-y-2">
      <MoveToJianListButton />
      <ActionPanel />
      <div className="w-full h-full p-4">
        <Suspense fallback={<div>Loading...</div>}>
          <Table />
        </Suspense>
      </div>
      <div className="h-8">{/* Spacer */}</div>
    </div>
  );
};
