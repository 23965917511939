import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Star, StarBorder } from '@mui/icons-material';

import { useHospitalList } from '../../../../hooks/useHospital';
import {
  decodeEmbodimentCode,
  decodeJurisdictionCode,
  decodeEstablishmentCode,
} from '../../../../utils/hospitalCode';

export const TableBody: React.VFC = () => {
  const { hospitalList } = useHospitalList();
  return (
    <tbody>
      {hospitalList.map((hospital) => (
        <tr
          key={hospital.hospitalId}
          className="w-full border-b last:border-0 border-darkblue_100 flex flex-nowrap items-center"
        >
          <td className="text-lg w-20 text-left p-4">
            <DetailButton id={hospital.hospitalId} />
          </td>
          <td className="text-lg w-8 text-left flex items-center justify-center">
            {hospital.favorite ? (
              <Star className="text-darkbule_100" />
            ) : (
              <StarBorder className="text-darkblue_30" />
            )}
          </td>
          <td className="text-lg w-48 p-4 break-words">{hospital.hospitalName}</td>
          <td className="text-lg w-40 text-left p-4 break-words">{hospital.organizationCode}</td>
          <td className="text-lg w-44 text-left p-4 break-words">{hospital.ownCode}</td>
          <td className="text-lg w-40 text-left p-4 break-words">
            {decodeEstablishmentCode(hospital.hospitalEstablishmentType)}
          </td>
          <td className="text-lg w-40 text-left p-4 break-words">
            {decodeJurisdictionCode(hospital.jurisdiction)}
          </td>
          <td className="text-lg w-48 text-left p-4 break-words">
            {decodeEmbodimentCode(hospital.hospitalEmbodimentType)}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const DetailButton: React.VFC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(`/hospital/${id}`)}
      className="bg-darkblue_10 hover:bg-darkblue_10/50 rounded-md border-2 border-darkblue_100 px-2 py-1"
    >
      <span className="text-sm font-bold">詳細</span>
    </button>
  );
};
