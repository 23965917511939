import * as Icon from 'react-feather';
import { useParams } from 'react-router-dom';

import { useDeletePatientInfo } from '../../../hooks/useJian';
import { ActionPanelButton } from '../../../components/ActionPanelButton';

export const DeletePatientInfoButton = () => {
  const { jianId, infoTypeId } = useParams<{ jianId: string; infoTypeId: string }>();
  const deletePatientInfo = useDeletePatientInfo(jianId ?? '', infoTypeId ?? '');

  const onClick = async () => {
    await deletePatientInfo();
  };

  return infoTypeId ? (
    <ActionPanelButton
      type="danger"
      onClick={onClick}
      label="傷病者削除"
      icon={<Icon.UserMinus size={24} />}
    />
  ) : (
    <ActionPanelButton
      type="danger"
      onClick={() => {}}
      label="傷病者削除"
      icon={<Icon.UserMinus size={24} />}
      isDisabled
    />
  );
};
