import { useState } from 'react';
import { Menu } from '@headlessui/react';
import { Download } from 'react-feather';

import { CsvOutputSelectModal } from './CsvOutputSelectModal';
import { useOACsvDownload } from '../../../../hooks/Download/useOACsvDownload';

type Props = {
  selectedJianIds: string[];
};

export const OutputMenuButton: React.VFC<Props> = ({ selectedJianIds }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const downloadOACSV = useOACsvDownload();
  return (
    <>
      <Menu as="div" className="relative inline-block">
        <Menu.Button className="flex items-center gap-2 px-2 py-1 bg-white rounded">
          <Download size={20} className="text-darkblue_100" />
          <span className="font-bold text-darkblue_100">ダウンロード</span>
        </Menu.Button>
        <Menu.Items className="absolute left-0 mt-1 w-72 border-2 border-darkblue_100 bg-white rounded py-1 z-50">
          <MenuItem
            label="国表CSV出力"
            description="国表をCSV形式で出力します"
            onClick={() => {
              if (selectedJianIds.length === 0) {
                window.alert('1件以上の事案をチェックボックスで選択してください');
                return;
              }
              downloadOACSV(selectedJianIds);
            }}
          />
          <MenuItem
            label="CSV選択出力"
            description="出力項目を選択しCSV形式で出力します"
            onClick={() => {
              if (selectedJianIds.length === 0) {
                window.alert('1件以上の事案をチェックボックスで選択してください');
                return;
              }
              setIsOpenModal(true);
            }}
          />
        </Menu.Items>
      </Menu>
      <CsvOutputSelectModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        selectedJianIds={selectedJianIds}
      />
    </>
  );
};

const MenuItem: React.VFC<{
  label: string;
  description: string;
  onClick?: () => void;
}> = ({ label, description, onClick }) => {
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`w-full text-left cursor-pointer px-3 py-2 space-y-1 border-b last:border-none border-darkblue_10 ${
            active ? 'bg-darkblue_10' : ''
          }`}
          onClick={onClick}
        >
          <p className="font-bold text-lg text-darkblue_100">{label}</p>
          <p className="text-sm text-darkblue_70">{description}</p>
        </button>
      )}
    </Menu.Item>
  );
};
