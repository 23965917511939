// 隊員の救命士認定区分の一覧を取得するためのHooks

import { useQuery } from '@tanstack/react-query';

import { memberApiClient } from '../utils/apiClient';

export const useEmtClasses = () => {
  const { data } = useQuery({
    queryKey: ['emtClasses'],
    queryFn: async () => {
      const res = await memberApiClient.GET('/emt-class-options');
      if (res.error) {
        throw new Error(`Failed to GET /emt-classes \n message: ${res.error.message}`);
      }
      return res.data;
    },
  });
  return data?.options ?? [];
};
