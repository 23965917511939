import React from 'react';
import { Edit2 } from 'react-feather';

type Props = {
  label?: string | undefined;
  disabled?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const EditButton: React.VFC<Props> = ({ label, disabled, onClick }) => {
  return (
    <button
      className="w-fit flex items-center gap-2 bg-darkblue_100 hover:bg-darkblue_70 disabled:bg-darkblue_50 rounded px-2 py-1"
      disabled={disabled}
      onClick={onClick}
    >
      <Edit2 size={20} className="text-white" />
      <span className="w-max text-white text-base font-bold">{label ?? '編集'}</span>
    </button>
  );
};
