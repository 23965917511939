// 事案一覧に関するロジックをまとめたHooks
import { useCallback } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';

import { datacrudApiClient as client } from '../utils/apiClient';
import type { components } from '../schema/data-crud';

// scopeをfireDepartmentとするアカウントのリスト
const whiteListUsers = [
  'fujisawakyuukyuu', // 救命課
  'shirei29', // 指令室
];
const getScope = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const scope = whiteListUsers.includes(user.username) ? 'fireDepartment' : 'team';
  return scope;
}; // TODO: scopeではなくサーバーサイドの権限で閲覧できる事案をコントロールしたい

const getJianList = async (limit: number, nextToken?: string | undefined) => {
  const scope = await getScope();
  const res = await client.GET('/jians', {
    params: {
      query: {
        scope,
        limit,
        nextToken,
      },
    },
  });
  if (res.error) {
    throw new Error(`Failed to GET /jians \n message: ${res.error.message}`);
  }
  return res.data;
};

const currentPageState = atom<number>({
  key: 'useJianList/currentPageState',
  default: 0,
});

const limitState = atom<number>({
  key: 'useJianList/limitState',
  default: 10,
});

export const useJianList = () => {
  const [page, setPage] = useRecoilState(currentPageState);
  const limit = useRecoilValue(limitState);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<
    components['schemas']['JianListResponse']
  >({
    queryKey: ['jians'],
    queryFn: async ({ pageParam = undefined }) => {
      return await getJianList(limit, pageParam);
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
  });

  const isLatestPage = data?.pages.length === page + 1;
  const isLastPage = isLatestPage && !hasNextPage;

  const incrementPage = useCallback(() => {
    if (isLastPage) return;
    if (isLatestPage) fetchNextPage();
    setPage(page + 1);
  }, [setPage, fetchNextPage, page, isLastPage, isLatestPage]);

  const decrementPage = useCallback(() => {
    setPage(page > 0 ? page - 1 : 0);
  }, [setPage, page]);

  return {
    jianList: data?.pages.at(page),
    page,
    limit,
    isLastPage,
    incrementPage,
    decrementPage,
  };
};

export const useRefreshJianList = () => {
  const queryClient = useQueryClient();
  const setPage = useSetRecoilState(currentPageState);

  return useCallback(() => {
    queryClient.resetQueries(['jians']);
    setPage(0);
  }, [setPage, queryClient]);
};
