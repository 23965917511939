import { VFC } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallBack } from '../../../components/ErrorFallBack';
import { FieldListRecursiveRender } from './FieldListRecursiveRender';
import { useFields } from '../../../hooks/useJianDetail';

export const FieldList: VFC = () => {
  const fields = useFields();

  return (
    <div className="flex flex-col border-darkblue_20 max-h-[calc(100vh-18rem)] overflow-y-scroll ">
      {fields
        .filter((field) => !field.parentFieldId)
        .sort((a, b) => (a.fieldOrder ?? 0) - (b.fieldOrder ?? 0))
        .map((field) => {
          return (
            <ErrorBoundary FallbackComponent={ErrorFallBack} key={field.fieldId}>
              <FieldListRecursiveRender fieldId={field.fieldId} />
            </ErrorBoundary>
          );
        })}
    </div>
  );
};
