import React from 'react';

export const TableHeader: React.VFC = () => {
  return (
    <thead className="sticky top-0 bg-white">
      <tr className="border-b-2 border-darkblue_100 flex flex-nowrap">
        <th className="text-lg w-24 text-left p-4">{/* 詳細ボタン用スペース */}</th>
        <th className="text-lg w-64 text-left p-4">パターン名</th>
        <th className="text-lg w-48 text-left p-4">作成者</th>
        <th className="text-lg w-60 text-left p-4">作成日時</th>
        <th className="text-lg w-60 text-left p-4">最終更新</th>
      </tr>
    </thead>
  );
};
