import React from 'react';

type Props = React.ComponentProps<'input'> & {
  label: string;
};

export const Input = ({ label, className, ...props }: Props) => (
  <label className="flex items-end">
    <input
      {...props}
      className={`border border-darkblue_80 rounded text-sm text-darkblue_100 px-2 py-1.5 w-16 ${className}`}
    />
    <span className="ml-0.5 text-sm text-darkblue_50">{label}</span>
  </label>
);
