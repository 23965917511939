import React from 'react';
import { CheckBox } from '../CheckBox';

type JianListHeaderProps = {
  fieldNames: string[];
  checked: boolean;
  onChange: (value: boolean) => void;
};

export const JianListHeader: React.VFC<JianListHeaderProps> = ({
  fieldNames,
  checked,
  onChange,
}) => {
  return (
    <thead>
      <tr className="p-0 border-b-[1px] boreder-b-white z-30">
        <th scope="col" className="p-0 sticky top-0 left-0 z-20 bg-darkblue_100">
          <CheckBox checked={checked} onChange={onChange} className="w-16 text-white" />
        </th>
        <th scope="col" className="sticky p-0 top-0 left-16 z-20  bg-darkblue_100">
          <div className="w-36 px-4 text-xl text-white text-center align-middle">覚知時刻</div>
        </th>
        {fieldNames.map((fieldName) => (
          <th key={fieldName} scope="col" className="sticky top-0 bg-darkblue_100 z-10">
            <div className={`z-2 text-xl text-white px-4 min-w-full whitespace-nowrap`}>
              {fieldName}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
