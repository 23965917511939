import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { errorCheckApiClient as client } from '../utils/apiClient';
import { useJian } from './useJian';

export const useErrorCheck = () => {
  const { jianId } = useParams<{ jianId: string }>();
  const jian = useJian(jianId);
  const { data, error, isLoading } = useQuery({
    queryKey: ['errorCheck', jianId, { updated_at: jian?.updatedAt }],
    queryFn: async () => {
      const res = await client.GET(`/error-check/jians/{jian_id}`, {
        params: {
          path: { jian_id: jianId ?? '' },
          query: { updated_at: jian?.updatedAt ?? '' },
        },
      });
      if (res.error) {
        throw new Error(`Failed to GET /error-check \n message: ${res.error.message}`);
      }
      return res.data;
    },
    refetchInterval: (data) => {
      if (data?.status === 'PENDING') {
        return 5 * 1000; // 5秒
      }
      return false;
    },
    suspense: false,
    retry: false,
    enabled: !!jianId && !!jian?.updatedAt,
  });

  if (data && data.dispatchInfo && data.patientInfo) {
    const dispatchErrorCount = data.dispatchInfo.fields.filter(
      (field) => field.error_messages.length > 0
    ).length;
    const dispatchWarningCount = data.dispatchInfo.fields.filter(
      (field) => field.warning_messages.length > 0
    ).length;
    const patientErrorCount = data.patientInfo.flatMap((info) =>
      info.fields.filter((field) => field.error_messages.length > 0)
    ).length;
    const patientWarningCount = data.patientInfo.flatMap((info) =>
      info.fields.filter((field) => field.warning_messages.length > 0)
    ).length;

    return {
      ...data,
      isLoading,
      queryError: error,
      errorCount: { dispatch: dispatchErrorCount, patient: patientErrorCount },
      warningCount: { dispatch: dispatchWarningCount, patient: patientWarningCount },
    };
  } else {
    return {
      ...data,
      isLoading,
      queryError: error,
      errorCount: null,
      warningCount: null,
    };
  }
};

export const useCategoryError = (categoryName: string) => {
  const { infoType, infoTypeId } = useParams<{
    infoType: 'dispatch-info' | 'patient-info';
    infoTypeId: string;
  }>();
  const { dispatchInfo, patientInfo } = useErrorCheck();

  const errorCount =
    infoType === 'patient-info'
      ? patientInfo
          ?.find((info) => info.patientInfoId === infoTypeId)
          ?.fields.filter(
            (field) => field.categoryName === categoryName && field.error_messages.length > 0
          ).length
      : dispatchInfo?.fields.filter(
          (field) => field.categoryName === categoryName && field.error_messages.length > 0
        ).length;

  const warningCount =
    infoType === 'patient-info'
      ? patientInfo
          ?.find((info) => info.patientInfoId === infoTypeId)
          ?.fields.filter(
            (field) => field.categoryName === categoryName && field.warning_messages.length > 0
          ).length
      : dispatchInfo?.fields.filter(
          (field) => field.categoryName === categoryName && field.warning_messages.length > 0
        ).length;

  return { errorCount, warningCount };
};

export const useFieldError = (fieldId: string) => {
  const { infoType, infoTypeId } = useParams<{
    infoType: 'dispatch-info' | 'patient-info';
    infoTypeId: string;
  }>();
  const { dispatchInfo, patientInfo } = useErrorCheck();
  return infoType === 'patient-info'
    ? patientInfo
        ?.find((info) => info.patientInfoId === infoTypeId)
        ?.fields.find((field) => field.field_id === fieldId)
    : dispatchInfo?.fields.find((field) => field.field_id === fieldId);
};
