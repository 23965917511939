import React from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';

import { formatJapaneseMonthString } from '../../utils/dateUtils';

type Props = {
  selectedDate: Date | undefined;
  calenderType: 'Japanese' | 'Western';
  calenderCursor: number;
  setCalenderCursor: (cursor: number) => void;
  onSelectDate: (date: Date) => void;
};

const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'] as const;

export const Calender: React.VFC<Props> = ({
  selectedDate,
  calenderType,
  calenderCursor,
  setCalenderCursor,
  onSelectDate,
}) => {
  const tempDate = selectedDate ?? new Date();
  const displayedDate = new Date(tempDate.getFullYear(), tempDate.getMonth() + calenderCursor, 1);
  const displayedYear = displayedDate.getFullYear();
  const displayedMonth = displayedDate.getMonth();

  // 日付の配列
  // ex) 該当月の日数が31のとき、[1, 2, ..., 31]
  const dates: Array<number> = Array.from(
    { length: getDaysInMonth(displayedYear, displayedMonth) },
    (_, i) => i + 1
  );

  // 月初の曜日によるカレンダーの空白分の配列
  // ex） 該当月の1日が水曜日のとき、['', '', '']
  const emptyDates: Array<''> = Array(getFirstDayOfMonth(displayedYear, displayedMonth)).fill('');

  // 選択された日かどうか
  const isSelectedDate = (date: number) =>
    selectedDate && calenderCursor === 0 && date === selectedDate.getDate();

  // 今日かどうか
  const isToday = (date: number) => {
    const today = new Date();
    return (
      displayedYear === today.getFullYear() &&
      displayedMonth === today.getMonth() &&
      date === today.getDate()
    );
  };

  return (
    <div className="space-y-2">
      <div className="flex gap-2 items-center justify-center">
        <button
          tabIndex={-1}
          onClick={() => setCalenderCursor(calenderCursor - 1)}
          className="text-darkblue_100"
        >
          <ChevronLeft size={20} />
        </button>
        <div className="text-darkblue_100 font-bold">
          {calenderType === 'Japanese'
            ? formatJapaneseMonthString(displayedYear, displayedMonth + 1)
            : `${displayedYear}年${displayedMonth + 1}月`}
        </div>
        <button
          tabIndex={-1}
          onClick={() => setCalenderCursor(calenderCursor + 1)}
          className="text-darkblue_100"
        >
          <ChevronRight size={20} />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-1 text-sm text-darkblue_100">
        {daysOfWeek.map((day) => (
          <div key={day} className="text-center text-darkblue_70">
            {day}
          </div>
        ))}
        {emptyDates.map((_, index) => (
          <div key={`spacer-${index}`}>{/* Spacer */}</div>
        ))}
        {dates.map((date) => (
          <button
            key={date}
            tabIndex={-1}
            className={`font-bold w-8 h-8 rounded text-center ${isToday(date) ? 'underline' : ''} ${
              isSelectedDate(date) ? 'bg-darkblue_100 text-white' : 'hover:bg-darkblue_10'
            }`}
            onClick={() => {
              const newDate = new Date(displayedYear, displayedMonth, date);
              onSelectDate(newDate);
            }}
          >
            {date}
          </button>
        ))}
      </div>
    </div>
  );
};

// 当該月の日数を取得
function getDaysInMonth(year: number, month: number) {
  return new Date(year, month + 1, 0).getDate();
}

// 当該月の1日の曜日を取得 (0: 日曜, 1: 月曜, ... , 6: 土曜)
function getFirstDayOfMonth(year: number, month: number) {
  return new Date(year, month, 1).getDay();
}
