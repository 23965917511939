import React from 'react';

import { ActionPanelBox } from '../../../components/ActionPanelBox';
import { useDeleteCsvPattern } from '../../../hooks/useCsvPattern';

type Props = { patternId: string; patternName: string; submitDisabled?: boolean };

export const ActionPanel: React.VFC<Props> = ({ patternId, patternName, submitDisabled }) => {
  const deleteCsvPattern = useDeleteCsvPattern(patternId);
  return (
    <ActionPanelBox>
      <h2 className="text-darkblue_100 font-bold text-xl">{patternName}</h2>
      <div className="grow">{/* Spacer */}</div>
      <button
        type="button"
        onClick={() => deleteCsvPattern()}
        className="text-red-600 font-bold text-lg rounded border border-red-600 hover:bg-black/10 px-3 py-1.5"
      >
        削除
      </button>
      <button
        type="submit"
        disabled={submitDisabled}
        className="text-white font-bold text-lg bg-darkblue_100 disabled:bg-darkblue_30 hover:bg-darkblue_70 rounded px-3 py-1.5"
      >
        保存
      </button>
    </ActionPanelBox>
  );
};
