import React, { useState } from 'react';

import { SubmitButton, CancelButton, InputText, Select } from '../Components';
import { useUpdateRole, type OperateLevel } from '../../../hooks/useRoles';
import { components } from '../../../schema/member-management';

type Props = {
  roleId: string;
  initValues: Required<components['schemas']['RoleUpdateRequest']>;
  onSubmit: () => void;
  onCancel: () => void;
};

export const RoleItemEditCard: React.VFC<Props> = ({ roleId, initValues, onSubmit, onCancel }) => {
  const [role, setRole] = useState(initValues);
  const { isUpdating, updateRole } = useUpdateRole();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateRole({ roleId, ...role });
    onSubmit();
  };

  return (
    <li>
      <form
        className="w-full flex flex-col gap-4 p-4 rounded-lg border-2 border-darkblue_70"
        onSubmit={handleSubmit}
      >
        <div className="space-y-2">
          <InputText
            label="ロール名"
            required
            value={role.roleName}
            onChangeValue={(roleName) => setRole({ ...role, roleName })}
          />
          <div className="pl-2 space-y-6">
            <div className="space-y-2">
              <div className="text-base text-darkblue_100 font-bold">事案管理権限</div>
              <div className="pl-4 space-y-2">
                <Select
                  label="自身が作成した事案に対する権限"
                  value={role.selfJianOperateLevel}
                  options={createOptions(true)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, selfJianOperateLevel: v });
                    }
                  }}
                />
                <Select
                  label="所属隊の全事案に対する権限"
                  value={role.teamJianOperateLevel}
                  options={createOptions(false)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, teamJianOperateLevel: v });
                    }
                  }}
                />
                <Select
                  label="本部内の全事案に対する権限"
                  value={role.fdJianOperateLevel}
                  options={createOptions(false)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, fdJianOperateLevel: v });
                    }
                  }}
                />
              </div>
            </div>
            <div className="space-y-2">
              <div className="text-base text-darkblue_100 font-bold">ロール管理権限</div>
              <div className="pl-4 space-y-2">
                <Select
                  label="ロールを管理する権限"
                  value={role.roleOperateLevel}
                  options={createOptions(true)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, roleOperateLevel: v });
                    }
                  }}
                />
              </div>
            </div>
            <div className="space-y-2">
              <div className="text-base text-darkblue_100 font-bold">救急隊グループ管理権限</div>
              <div className="pl-4 space-y-2">
                <Select
                  label="所属隊を管理する権限"
                  value={role.selfTeamOperateLevel}
                  options={createOptions(false)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, selfTeamOperateLevel: v });
                    }
                  }}
                />
                <Select
                  label="本部内の全隊を管理する権限"
                  value={role.fdTeamOperateLevel}
                  options={createOptions(true)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, fdTeamOperateLevel: v });
                    }
                  }}
                />
              </div>
            </div>
            <div className="space-y-2">
              <div className="text-base text-darkblue_100 font-bold">隊員アカウント管理権限</div>
              <div className="pl-4 space-y-2">
                <Select
                  label="自身のアカウントを管理する権限"
                  value={role.selfMemberOperateLevel}
                  options={createOptions(false)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, selfMemberOperateLevel: v });
                    }
                  }}
                />
                <Select
                  label="所属隊の全アカウントを管理する権限"
                  value={role.teamMemberOperateLevel}
                  options={createOptions(true)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, teamMemberOperateLevel: v });
                    }
                  }}
                />
                <Select
                  label="本部内の全アカウントを管理する権限"
                  value={role.fdMemberOperateLevel}
                  options={createOptions(true)}
                  onChangeValue={(v) => {
                    if (isOperateLevel(v)) {
                      setRole({ ...role, fdMemberOperateLevel: v });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center gap-2">
          <CancelButton disabled={isUpdating} onClick={() => onCancel()} />
          <SubmitButton disabled={isUpdating} />
        </div>
      </form>
    </li>
  );
};

const createOptions = (isCreatable: boolean) => {
  if (isCreatable) {
    return [
      { value: 'ALL_DENY', label: '無し' },
      { value: 'CAN_READ', label: '閲覧' },
      { value: 'CAN_CREATE', label: '閲覧, 作成' },
      { value: 'CAN_UPDATE', label: '閲覧, 作成, 編集' },
      { value: 'CAN_DELETE', label: '閲覧, 作成, 編集, 削除' },
    ];
  } else {
    return [
      { value: 'ALL_DENY', label: '無し' },
      { value: 'CAN_READ', label: '閲覧' },
      { value: 'CAN_UPDATE', label: '閲覧, 編集' },
      { value: 'CAN_DELETE', label: '閲覧, 編集, 削除' },
    ];
  }
};

const isOperateLevel = (value: string): value is OperateLevel => {
  return ['ALL_DENY', 'CAN_READ', 'CAN_CREATE', 'CAN_UPDATE', 'CAN_DELETE'].includes(value);
};
