import { VFC } from 'react';

import { formatJapaneseDateString, formatJapaneseDateTimeString } from '../../../utils/dateUtils';

type JianListItemCellProps = {
  fieldType: string;
  fieldValue?: string | string[] | number | null;
  onClick: () => void;
};

export const JianListItemCell: VFC<JianListItemCellProps> = ({
  fieldType,
  fieldValue,
  onClick,
}) => {
  const value = createDisplayValue(fieldType, fieldValue);

  return (
    <td className={`bg-darkblue_10 border-x-[1px] border-x-white`}>
      <button className="w-full" onClick={onClick}>
        <div className="text-xl flex justify-center content-center items-center p-4 whitespace-nowrap">
          {value}
        </div>
      </button>
    </td>
  );
};

export const createDisplayValue = (
  fieldType: string,
  value?: string | string[] | number | null
) => {
  if (!value) {
    return '';
  }

  if (fieldType === 'DATE') {
    const date = new Date(value as string);
    return formatJapaneseDateString(date);
  }

  if (fieldType === 'DATETIME') {
    const date = new Date(value as string);
    return formatJapaneseDateTimeString(date);
  }

  return value;
};
