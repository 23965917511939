import React from 'react';
import { PlusCircle, RefreshCcw } from 'react-feather';

import { ActionPanelBox } from '../../../components/ActionPanelBox';
import { useRereshCsvPatterns, useCreateCsvPattern } from '../../../hooks/useCsvPattern';

export const ActionPanel: React.VFC = () => {
  return (
    <ActionPanelBox>
      <CreateButton />
      <RefreshButton />
    </ActionPanelBox>
  );
};

const CreateButton: React.VFC = () => {
  const create = useCreateCsvPattern();
  return (
    <button
      onClick={() => create()}
      className="w-fit h-fit flex items-center gap-2 bg-darkblue_100 hover:bg-darkblue_70 rounded px-3 py-2"
    >
      <PlusCircle size={24} className="text-white" />
      <span className="text-white font-bold text-lg">新規作成</span>
    </button>
  );
};

const RefreshButton: React.VFC = () => {
  const refresh = useRereshCsvPatterns();
  return (
    <button
      onClick={() => refresh()}
      className="w-fit h-fit flex items-center gap-2 bg-darkblue_100 hover:bg-darkblue_70 rounded px-3 py-2"
    >
      <RefreshCcw size={24} className="text-white" />
      <span className="text-white font-bold text-lg">更新</span>
    </button>
  );
};
