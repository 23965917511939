import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

export const Hospital: React.VFC = () => {
  return (
    <div className="w-full h-full px-24">
      <h1 className="text-2xl font-bold text-darkblue_100 mb-4">病院管理</h1>
      <div className="h-3/4 flex gap-4">
        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
};
