import { Suspense } from 'react';

import { SettingButton } from './SettingButton';
import { UserNameDisplay } from './UserNameDisplay';
import { YearButton } from './YearButton';

export const StatusBar = () => {
  return (
    <div className="fixed top-0 right-0 h-16 w-auto p-2 bg-darkblue_100 rounded-bl-lg flex shadow shadow-darkblue_40 z-30">
      <div className="flex flex-row justify-end gap-6 items-center self-center m-4">
        <YearButton />
        <SettingButton />
        <div className="flex flex-col justify-start">
          <Suspense
            fallback={<div className="animate-pulse text-white text-sm">ユーザー情報取得中</div>}
          >
            <UserNameDisplay />
          </Suspense>
        </div>
      </div>
    </div>
  );
};
