import { useCallback } from 'react';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';

import { useFieldMaster } from '../../../hooks/useFieldMaster';
import { SearchCondition, searchConditionListState } from './useSearchCondition';
import { createRequestCondition } from './utils/createRequestCondition';
import { datacrudApiClient as client } from '../../../utils/apiClient';
import type { components } from '../../../schema/data-crud';

const search = async (searchRequest: components['schemas']['SearchRequest']) => {
  const res = await client.POST('/search', {
    body: searchRequest,
  });
  if (res.error) {
    throw new Error(`Failed to POST /search \n message: ${res.error.message}`);
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Recoil States
// -----------------------------------------------------------------------------
const searchResultJiansState = atom<components['schemas']['SearchResponse'] | undefined>({
  key: 'useSearch/searchResultJiansState',
  default: undefined,
});

const selectedInfoTypeState = atom<'jian' | 'dispatchInfo' | 'patientInfo'>({
  key: 'useSearch/selectedInfoTypeState',
  default: 'jian',
});

const isSearchingState = atom<boolean>({
  key: 'useSearch/isSearching',
  default: false,
});

// -----------------------------------------------------------------------------
// Hook
// -----------------------------------------------------------------------------
export const useSearch = () => {
  const fieldMaster = useFieldMaster();
  const [selectedInfoType, setSelectedInfoType] = useRecoilState(selectedInfoTypeState);
  const [searchConditionList, setSearchConditionList] = useRecoilState(searchConditionListState);
  const [result, setResult] = useRecoilState(searchResultJiansState);
  const [isSearching, setIsSearching] = useRecoilState(isSearchingState);
  const resetList = useResetRecoilState(searchConditionListState);

  const getFieldType = useCallback(
    (fieldId: string) => {
      const field = [...fieldMaster.dispatchInfoCategories, ...fieldMaster.patientInfoCategories]
        .flatMap((category) => category.fields)
        .find((field) => field.fieldId === fieldId);
      return field?.fieldType ?? '';
    },
    [fieldMaster]
  );

  const submit = useCallback(async () => {
    setIsSearching(true);
    try {
      const data = await search({
        target: selectedInfoType,
        conditions: searchConditionList.map((condition) =>
          createRequestCondition(
            condition.fieldId,
            getFieldType(condition.fieldId),
            condition.operator,
            condition.value
          )
        ),
      });
      setResult(data);
    } catch (error) {
      window.alert('検索に失敗しました。');
      console.error(error);
    } finally {
      setIsSearching(false);
    }
  }, [getFieldType, searchConditionList, selectedInfoType, setResult, setIsSearching]);

  // 「事案 <-> 出場情報 <-> 傷病者情報」の変更
  const setInfoType = useCallback(
    (infoType: string) => {
      if (infoType === 'jian' || infoType === 'dispatchInfo' || infoType === 'patientInfo') {
        setSelectedInfoType(infoType);
        resetList();
      }
    },
    [setSelectedInfoType, resetList]
  );

  // 検索条件の追加
  const createNewCondition = useCallback(
    (id?: string | undefined) => {
      const newCondition: SearchCondition = {
        id: id ?? Math.random().toString(36).slice(-8), // idがundefinedの時、ランダムな8桁の文字列を生成
        categoryName: '',
        fieldId: '',
        operator: '',
        value: [''],
      };
      setSearchConditionList((prev) => [...prev, newCondition]);
    },
    [setSearchConditionList]
  );

  return {
    selectedInfoType,
    searchConditionList,
    setInfoType,
    createNewCondition,
    isSearching,
    submit,
    result,
  };
};
