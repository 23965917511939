import React from 'react';

import { HeaderCell } from './HeaderCell/HeaderCell';
import { MemberCell } from './MemberCell/MemberCell';
import { LicenseBadge } from './LicenseBadge/LicenseBadge';
import { components } from '../../../schema/member-management';

type Props = {
  members: Pick<
    components['schemas']['Member'],
    'memberId' | 'memberName' | 'teamName' | 'memberCode' | 'licenses' | 'emtClass'
  >[];
  onSelectMember: (
    member: Pick<
      components['schemas']['Member'],
      'memberCode' | 'memberName' | 'licenses' | 'emtClass'
    >
  ) => void;
};

export const MemberTable: React.VFC<Props> = ({ members, onSelectMember }) => {
  return (
    <table aria-label="隊員一覧" className="w-max h-max border-separate border-spacing-0">
      <thead>
        <tr>
          <HeaderCell className="w-28">氏名</HeaderCell>
          <HeaderCell className="w-28">所属</HeaderCell>
          <HeaderCell className="w-24">隊員コード</HeaderCell>
          <HeaderCell className="w-32">救命士認定区分</HeaderCell>
          <HeaderCell className="w-max">資格認定状況</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {members.map((member) => (
          <tr
            key={member.memberId}
            aria-label={member.memberName}
            className="hover:bg-slate-100 cursor-pointer"
            onClick={() =>
              onSelectMember({
                memberCode: member.memberCode,
                memberName: member.memberName,
                licenses: member.licenses,
                emtClass: member.emtClass,
              })
            }
          >
            <MemberCell className="w-28">{member.memberName}</MemberCell>
            <MemberCell className="w-28">{member.teamName}</MemberCell>
            <MemberCell className="w-24">{member.memberCode}</MemberCell>
            <MemberCell className="w-32">{member.emtClass}</MemberCell>
            <MemberCell className="w-max">
              <span className="flex gap-1">
                {member.licenses.map((license) => (
                  <LicenseBadge key={license.licenseId} text={license.licenseName} />
                ))}
              </span>
            </MemberCell>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
