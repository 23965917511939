import { VFC } from 'react';

type ErrorBadgesProps = {
  error?: number;
  warning?: number;
};

export const ErrorBadges: VFC<ErrorBadgesProps> = ({ error, warning }) => {
  return (
    <div className="self-center flex gap-2">
      {!!error && <div className="content-center text-white rounded bg-error w-8 h-8">{error}</div>}
      {!!warning && (
        <div className="content-center text-white rounded bg-warning w-8 h-8">{warning}</div>
      )}
    </div>
  );
};
