import React, { Suspense } from 'react';
import { ActionPanel } from './ActionPanel';
import { MemberList } from './MemberList';
import { useMe } from '../../../hooks/useMe';

export const MemberMaster: React.VFC = () => {
  const me = useMe();
  return (
    <div className="w-full h-full space-y-2">
      <ActionPanel />
      <div className="w-full h-full rounded-lg border-2 border-darkblue_100 overflow-y-auto p-4">
        <Suspense fallback={<div>Loading...</div>}>
          {me?.masterPermissions?.canListMembers ? <MemberList /> : <div>Permission Denied</div>}
        </Suspense>
      </div>
    </div>
  );
};
