import { union, difference } from 'lodash';
import { useCallback, useState } from 'react';

export const useCheckFields = () => {
  const [checkedFieldIds, setCheckedFieldIds] = useState<string[]>([]);

  const checkFields = useCallback(
    (fieldIds: string[]) => {
      setCheckedFieldIds((prev) => union(prev, fieldIds));
    },
    [setCheckedFieldIds]
  );

  const uncheckFields = useCallback(
    (fieldIds: string[]) => {
      setCheckedFieldIds((prev) => difference(prev, fieldIds));
    },
    [setCheckedFieldIds]
  );

  const reset = useCallback(() => {
    setCheckedFieldIds([]);
  }, [setCheckedFieldIds]);

  return {
    checkedFieldIds,
    checkFields,
    uncheckFields,
    reset,
  };
};
