import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { ActionPanelButton } from '../../../components/ActionPanelButton';

export const MoveToHospitalListButton = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/hospital');
  };
  return (
    <div className="fixed top-0 left-16 ml-4 h-16 flex flex-column items-center z-30">
      <ActionPanelButton
        type="light"
        onClick={onClick}
        label={'病院一覧へ'}
        icon={<Icon.List size={24} />}
      />
    </div>
  );
};
