import { difference, union } from 'lodash';
import { useCallback, useState } from 'react';

export const useJianSelection = () => {
  const [selectedJianIds, setSelectedJianIds] = useState<string[]>([]);

  // 事案が選択されているか
  const isSelected = useCallback(
    (jianId: string) => {
      return selectedJianIds.includes(jianId);
    },
    [selectedJianIds]
  );

  // 複数の事案がすべて選択されているか
  const areAllSelected = useCallback(
    (jianIds: string[]) => {
      return jianIds.every((jianId) => isSelected(jianId));
    },
    [isSelected]
  );

  // 事案を一つまたは複数、選択する
  const selectJianId = useCallback(
    (jianId: string | string[]) => {
      setSelectedJianIds((prev) => union(prev, typeof jianId === 'string' ? [jianId] : jianId));
    },
    [setSelectedJianIds]
  );

  // 事案を一つまたは複数、選択解除する
  const deselectJianId = useCallback(
    (jianId: string | string[]) => {
      setSelectedJianIds((prev) =>
        difference(prev, typeof jianId === 'string' ? [jianId] : jianId)
      );
    },
    [setSelectedJianIds]
  );

  return { selectedJianIds, isSelected, areAllSelected, selectJianId, deselectJianId };
};
