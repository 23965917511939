import React from 'react';

type Props = {
  teams: {
    teamId: string;
    teamName: string;
  }[];
  value: string;
  onChange: (teamId: string) => void;
};

export const TeamSelect: React.VFC<Props> = ({ teams, value, onChange }) => {
  return (
    <label className="flex flex-col gap-1">
      <span className="text-sm text-darkblue_100 font-bold">署所</span>
      <select
        className="text-sm text-darkblue_100 border border-darkblue_100 rounded cursor-pointer py-1.5"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        <option key={'ALL'} value={'ALL'}>
          すべて
        </option>
        {teams.map((team) => (
          <option key={team.teamId} value={team.teamId}>
            {team.teamName}
          </option>
        ))}
      </select>
    </label>
  );
};
