import React from 'react';

export const HospitalCell: React.FC<React.ComponentPropsWithoutRef<'td'>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <td
      className={`px-1 py-1.5 border-b border-darkblue_100 text-sm text-darkblue_100 text-left ${className}`}
      {...props}
    >
      {children}
    </td>
  );
};
