import React, { Suspense } from 'react';
import { Popover } from '@headlessui/react';
import { PlusCircle } from 'react-feather';

import { useMe } from '../../../hooks/useMe';
import { useRefreshMembers } from '../../../hooks/useMembers';
import { ActionPanelBox, RefreshButton } from '../Components';
import { NewMemberEditCard } from './NewMemberEditCard';

export const ActionPanel: React.VFC = () => {
  const me = useMe();
  const refresh = useRefreshMembers();
  return (
    <ActionPanelBox>
      {me?.masterPermissions?.canCreateTeam && (
        <Popover className="relative">
          <Popover.Button className="w-fit h-fit flex items-center gap-2 bg-darkblue_100 hover:bg-darkblue_70 rounded px-3 py-2">
            <PlusCircle size={24} className="text-white" />
            <span className="text-white font-bold text-lg">新規作成</span>
          </Popover.Button>
          <Popover.Panel className="absolute z-10 top-12">
            {({ close }) => (
              <Suspense
                fallback={
                  <div className="w-full p-4 rounded-lg border-2 border-darkblue_70 bg-white">
                    Loading...
                  </div>
                }
              >
                <NewMemberEditCard onSubmit={() => close()} onCancel={() => close()} />
              </Suspense>
            )}
          </Popover.Panel>
        </Popover>
      )}

      <RefreshButton onClick={() => refresh()} />
    </ActionPanelBox>
  );
};
