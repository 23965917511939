import React from 'react';
import { useSearchCondition } from '../../hooks/useSearchCondition';
import { Select } from '../atoms/Select';
import { useFieldMaster } from '../../../../hooks/useFieldMaster';

type CategorySelectorProps = {
  id: string;
  infoType: 'jian' | 'dispatchInfo' | 'patientInfo';
};

export const CategorySelector: React.VFC<CategorySelectorProps> = ({ id, infoType }) => {
  const { searchCondition, setCategory } = useSearchCondition(id);
  const fieldMaster = useFieldMaster();

  const dispatchCategoryOptions = fieldMaster.dispatchInfoCategories.map(({ categoryName }) => {
    return (
      <option key={categoryName} value={categoryName}>
        {categoryName}
      </option>
    );
  });
  const patientCategoryOptions = fieldMaster.patientInfoCategories.map(({ categoryName }) => (
    <option key={categoryName} value={categoryName}>
      {categoryName}
    </option>
  ));

  return (
    <Select
      name="category"
      value={searchCondition?.categoryName ?? ''}
      onChange={(e) => setCategory(e.target.value)}
      required
    >
      <option value="" hidden>
        カテゴリを選択
      </option>
      {infoType === 'dispatchInfo' ? (
        dispatchCategoryOptions
      ) : infoType === 'patientInfo' ? (
        patientCategoryOptions
      ) : (
        <>
          <optgroup label="出場情報">{dispatchCategoryOptions}</optgroup>
          <optgroup label="傷病者情報">{patientCategoryOptions}</optgroup>
        </>
      )}
    </Select>
  );
};
