import React from 'react';

import { MemberItem } from './MemberItem';
import { useMembers } from '../../../hooks/useMembers';

export const MemberList: React.VFC = () => {
  const members = useMembers();

  return (
    <ul className="space-y-2">
      {members.map((member) => (
        <MemberItem key={member.memberId} {...member} />
      ))}
    </ul>
  );
};
