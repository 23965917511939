import React from 'react';

type Props = {
  value: string;
  onChange: (input: string) => void;
};

export const NameOrCodeInput: React.VFC<Props> = ({ value, onChange }) => {
  return (
    <label className="w-full flex flex-col gap-1">
      <span className="text-sm text-darkblue_100 font-bold">隊員名または隊員コードで絞り込み</span>
      <input
        className="text-sm text-darkblue_100 border border-darkblue_100 rounded px-2 py-1.5"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </label>
  );
};
