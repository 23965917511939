import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';

import { useChangeMemberPassword } from '../../../hooks/useMembers';
import { CancelButton, InputText, SubmitButton, ViewText } from '../Components';
import { escapedSymbolArray, checkPassword } from './utils';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  memberId: string;
  loginId: string;
};

export const PasswordChangeDialog: React.VFC<DialogProps> = ({
  open,
  onClose,
  memberId,
  loginId,
}) => {
  const [password, setPassword] = useState('');
  const { isChangingPassword, changePassword } = useChangeMemberPassword();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!checkPassword(password)) {
      return;
    }
    await changePassword({ memberId, password });
    onClose();
    setPassword('');
  };

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center">
          <Dialog.Panel className="w-160 space-y-3 px-8 py-4 rounded-lg border-2 border-darkblue_70 bg-white">
            <Dialog.Title className="text-xl text-darkblue_100 font-bold text-center">
              パスワード変更
            </Dialog.Title>
            <Dialog.Description className="text-sm text-darkblue_100">
              パスワードを変更すると古いパスワードは使えなくなります。
            </Dialog.Description>
            <form className="space-y-3 pl-2" onSubmit={handleSubmit}>
              <ViewText label="ログインID" value={loginId} />
              <InputText
                label="パスワード"
                required
                minlength={8}
                pattern={`^[a-zA-Z0-9${escapedSymbolArray.join('')}]*$`}
                autoComplete="off"
                title="数字・英小字・英大字・記号をそれぞれ1つ以上含んだ8字以上の文字列を入力してください"
                value={password}
                onChangeValue={(v) => setPassword(v)}
              />
              <div className="flex justify-end items-center gap-2">
                <CancelButton
                  disabled={isChangingPassword}
                  onClick={() => {
                    onClose();
                    setPassword('');
                  }}
                />
                <SubmitButton disabled={isChangingPassword} label="適用" />
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
