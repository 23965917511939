import { Outlet } from 'react-router-dom';

import { AppHeader } from './AppHeader';
import { StatusBar } from './StatusBar/StatusBar';
import { Helmet } from 'react-helmet-async';

export const HeaderComponent = () => {
  return (
    <div className="relative scroll-smooth">
      <Helmet>
        <title>NOA</title>
      </Helmet>
      <AppHeader />
      <StatusBar />
      <div className="w-screen h-screen pt-24">
        <Outlet />
      </div>
    </div>
  );
};
