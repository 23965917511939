import { RefreshCcw } from 'react-feather';

type Props = {
  label?: string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const RefreshButton: React.VFC<Props> = ({ label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="w-fit h-fit flex items-center gap-2 bg-darkblue_100 hover:bg-darkblue_70 rounded px-3 py-2"
    >
      <RefreshCcw size={24} className="text-white" />
      <span className="text-white font-bold text-lg">{label ?? '更新'}</span>
    </button>
  );
};
