import React from 'react';
import { useSearchCondition } from '../../hooks/useSearchCondition';
import { Select } from '../atoms/Select';

type OperatorSelectorProps = { id: string; type: 'numeric' | 'string' | 'multiplechoice' };

export const OperatorSelector: React.VFC<OperatorSelectorProps> = ({ id, type }) => {
  const { searchCondition, setOperator } = useSearchCondition(id);

  return (
    <Select
      name="operator"
      className="font-normal border"
      value={searchCondition?.operator ?? ''}
      onChange={(e) => setOperator(e.target.value)}
      required
    >
      <option value="" hidden>
        条件を選択
      </option>
      <option value="eq">完全一致</option>
      {type === 'numeric' && <option value="ge">最小値</option>}
      {type === 'numeric' && <option value="le">最大値</option>}
      {type === 'numeric' && <option value="between">範囲選択</option>}
      {type === 'multiplechoice' && <option value="contain">含む</option>}
      {type === 'string' && <option value="contain">部分一致</option>}
      {type === 'string' && <option value="startsWith">前方一致</option>}
    </Select>
  );
};
