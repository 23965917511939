type Props = {
  label: string;
  required?: boolean;
  minlength?: number;
  maxlength?: number;
  pattern?: string;
  autoComplete?: string;
  title?: string;
  value: string;
  onChangeValue: (value: string) => void;
};

export const InputText: React.VFC<Props> = ({
  label,
  required,
  minlength,
  maxlength,
  title,
  pattern,
  autoComplete,
  value,
  onChangeValue,
}) => {
  return (
    <div className="flex items-center gap-3 text-left">
      <label htmlFor={label} className="min-w-max text-sm text-darkblue_70 font-bold">
        {label}
      </label>
      <input
        className="w-60 rounded border border-darkblue_70 px-2 py-1 text-darkblue_100"
        name={label}
        required={required}
        minLength={minlength}
        maxLength={maxlength}
        pattern={pattern}
        autoComplete={autoComplete}
        title={title}
        value={value}
        onChange={(e) => onChangeValue(e.target.value)}
      />
      {title && <div className="w-72 text-xs text-darkblue_70">{title}</div>}
    </div>
  );
};
