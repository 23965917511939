import { Suspense, useCallback, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Download, Circle, CheckCircle } from 'react-feather';

import { useCsvSelectedDownload } from '../../../../hooks/Download/useCsvSelectedDownload';
import { useCheckFields } from '../../hooks/useCheckFields';
import { useCsvPatterns, useCheckCompatibility } from '../../../../hooks/useCsvPattern';
import { FieldCheckboxList } from '../../../FieldCheckboxList';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectedJianIds: string[];
};

export const CsvOutputSelectModal: React.VFC<Props> = ({ isOpen, onClose, selectedJianIds }) => {
  const [selectType, setSelectType] = useState<'pattern' | 'custom'>('pattern');
  const [selectedPatternId, setSelectedPatternId] = useState<string>('');
  const downloadCSV = useCsvSelectedDownload();
  const patterns = useCsvPatterns();
  const checkCompatibility = useCheckCompatibility(selectedPatternId);
  const { checkedFieldIds, checkFields, uncheckFields, reset } = useCheckFields();

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleSubmit = useCallback(async () => {
    downloadCSV(selectedJianIds, checkedFieldIds);
    handleClose();
  }, [downloadCSV, selectedJianIds, checkedFieldIds, handleClose]);

  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
      <div className="fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="w-fit bg-white border-2 border-darkblue_100 rounded-xl p-4 space-y-3">
          <Dialog.Title className="font-bold text-2xl text-darkblue_100">
            CSV出力項目選択
          </Dialog.Title>

          <Dialog.Description className="text-darkblue_70 text-sm">
            CSVファイルに出力する項目を選択してください
          </Dialog.Description>

          <ul className="space-y-1 ">
            <li className="flex items-center gap-4">
              <button
                className="flex items-center gap-2 text-darkblue_100 text-base"
                onClick={() => {
                  setSelectType('pattern');
                  reset();
                  checkFields(
                    patterns.find((pattern) => pattern.patternId === selectedPatternId)!.pattern
                  );
                }}
              >
                {selectType === 'pattern' ? <CheckCircle size={20} /> : <Circle size={20} />}
                登録したパターンから選ぶ
              </button>
              <select
                disabled={selectType === 'custom'}
                className={`w-60 rounded border px-2 py-1 text-darkblue_100 ${
                  selectType === 'custom' ? 'border-darkblue_30' : 'border-darkblue_100'
                }`}
                value={selectedPatternId}
                onChange={(e) => {
                  setSelectedPatternId(e.target.value);
                  reset();
                  checkFields(
                    patterns.find((pattern) => pattern.patternId === e.target.value)!.pattern
                  );
                }}
              >
                <option value={''}>--- 未選択 ---</option>
                {patterns.map((pattern) => (
                  <PatternOption
                    key={pattern.patternId}
                    patternId={pattern.patternId}
                    patternName={pattern.patternName}
                  />
                ))}
              </select>
            </li>
            <li>
              <button
                className="flex items-center gap-2 text-darkblue_100 text-base"
                onClick={() => {
                  setSelectType('custom');
                  reset();
                }}
              >
                {selectType === 'custom' ? <CheckCircle size={20} /> : <Circle size={20} />}
                ここで項目を選択する
              </button>
            </li>
          </ul>

          <div className="py-2">
            <Suspense fallback={<div>Loading data...</div>}>
              <FieldCheckboxList
                checkedFieldIds={checkedFieldIds}
                disabled={selectType === 'pattern'}
                checkFields={checkFields}
                uncheckFields={uncheckFields}
              />
            </Suspense>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={handleSubmit}
              disabled={selectType === 'pattern' && (!selectedPatternId || !checkCompatibility())}
              className="flex items-center gap-2 px-3 py-2 rounded-lg bg-darkblue_100 disabled:bg-darkblue_40"
            >
              <Download size={20} className="text-white" />
              <span className="text-white font-bold text-md">ダウンロード</span>
            </button>
            {!checkCompatibility() && (
              <p className="text-red-400 text-sm">
                このパターンは指定された年度と互換性がありません
              </p>
            )}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const PatternOption: React.VFC<{ patternId: string; patternName: string }> = ({
  patternId,
  patternName,
}) => {
  const checkCompatibility = useCheckCompatibility(patternId);
  return (
    <option value={patternId}>
      {checkCompatibility() ? patternName : `${patternName}(互換性なし)`}
    </option>
  );
};
