import React from 'react';

type Props = {
  label?: string | undefined;
  disabled?: boolean | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const SubmitButton: React.VFC<Props> = ({ label, disabled, onClick }) => {
  return (
    <button
      type="submit"
      className="bg-darkblue_100 font-bold text-white hover:bg-darkblue_70 disabled:bg-darkblue_50 rounded px-2 py-1"
      disabled={disabled}
      onClick={onClick}
    >
      {label ?? '変更を適用'}
    </button>
  );
};
