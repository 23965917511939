// Note: 出場情報および傷病者情報のロジックを担うHooks
import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { orderBy } from 'lodash';

import { datacrudApiClient as client } from '../utils/apiClient';
import { components } from '../schema/data-crud';
import { NavigateOptions, To, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { nonNullable } from '../utils/nonNullable';
import { defaultValue } from '../utils/defaultValue';
import toast from 'react-hot-toast';

export type JianDetailInfo = {
  jianId: string;
  infoType: 'dispatch-info' | 'patient-info';
  infoTypeId: string;
  categories: components['schemas']['Category'][];
  createdAt: string;
  updatedAt: string;
};

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const queryDispatchInfo = async (
  jianId: string,
  dispatchInfoId: string
): Promise<JianDetailInfo> => {
  const res = await client.GET(`/jians/{jianId}/dispatch-info/{dispatchInfoId}`, {
    params: {
      path: { jianId, dispatchInfoId },
    },
  });
  if (res.error) {
    throw new Error(
      `Failed to GET /jians/${jianId}/dispatch-info/${dispatchInfoId} \n message: ${res.error.message}`
    );
  }
  return {
    jianId: res.data.jianId,
    infoType: 'dispatch-info',
    infoTypeId: res.data.dispatchInfoId,
    categories: res.data.categories,
    createdAt: res.data.createdAt,
    updatedAt: res.data.updatedAt,
  };
};

const updateDispathInfo = async (
  jianId: string,
  dispatchInfoId: string,
  fields: { fieldId: string; value: components['schemas']['Value'] }[]
): Promise<components['schemas']['FieldsUpdateResponse']> => {
  const res = await client.PUT(`/jians/{jianId}/dispatch-info/{dispatchInfoId}/fields`, {
    params: {
      path: { jianId, dispatchInfoId },
    },
    body: fields,
  });
  if (res.error) {
    throw new Error(
      `Failed to PUT /jians/${jianId}/dispatch-info/${dispatchInfoId}/fields \n message: ${res.error.message}`
    );
  }
  return res.data;
};

const queryPatientInfo = async (jianId: string, patientInfoId: string): Promise<JianDetailInfo> => {
  const res = await client.GET(`/jians/{jianId}/patient-info/{patientInfoId}`, {
    params: {
      path: { jianId, patientInfoId },
    },
  });
  if (res.error) {
    throw new Error(
      `Failed to GET /jians/${jianId}/patient-info/${patientInfoId} \n message: ${res.error.message}`
    );
  }
  return {
    jianId: res.data.jianId,
    infoType: 'patient-info',
    infoTypeId: res.data.patientInfoId,
    categories: res.data.categories,
    createdAt: res.data.createdAt,
    updatedAt: res.data.updatedAt,
  };
};

const updatePatientInfo = async (
  jianId: string,
  patientInfoId: string,
  fields: { fieldId: string; value: components['schemas']['Value'] }[]
): Promise<components['schemas']['FieldsUpdateResponse']> => {
  const res = await client.PUT(`/jians/{jianId}/patient-info/{patientInfoId}/fields`, {
    params: {
      path: { jianId, patientInfoId },
    },
    body: fields,
  });
  if (res.error) {
    throw new Error(
      `Failed to PUT /jians/${jianId}/patient-info/${patientInfoId}/fields \n message: ${res.error.message}`
    );
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Recoil States
// -----------------------------------------------------------------------------
const localFieldsState = atom<
  | {
      jianId: string;
      infoType: 'dispatch-info' | 'patient-info';
      infoTypeId: string;
      fields: { fieldId: string; value: components['schemas']['Value'] }[];
    }
  | undefined
>({
  key: 'useDispatchOrPatientInfo/localFieldsState',
  default: undefined,
});

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
// 出場情報または傷病者情報を取得する
export const useJianDetailInfo = () => {
  const { jianId, infoType, infoTypeId } = useParams<{
    jianId: string;
    infoType: 'dispatch-info' | 'patient-info';
    infoTypeId: string;
  }>();
  const { data } = useQuery({
    queryKey: ['jian', jianId, infoType, infoTypeId],
    queryFn: async () => {
      if (!jianId || !infoType || !infoTypeId) {
        throw new Error('jianId, infoType, infoTypeId are required');
      }
      switch (infoType) {
        case 'dispatch-info':
          return await queryDispatchInfo(jianId, infoTypeId);
        case 'patient-info':
          return await queryPatientInfo(jianId, infoTypeId);
      }
    },
    enabled: !!jianId && !!infoType && !!infoTypeId,
  });

  return data;
};

// 選択中の出場情報または傷病者情報について、カテゴリ名のリストを取得する
export const useCategoryNames = () => {
  const info = useJianDetailInfo();

  return orderBy(info?.categories, 'categoryOrder')
    .map((category) => category.categoryName)
    .filter(nonNullable);
};

// 選択中のカテゴリを取得・カテゴリを選択する
export const useSelectCatetory = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCategoryName = searchParams.get('categoryName');
  const selectCategory = useCallback(
    (categoryName: string) => {
      setSearchParams({ categoryName });
    },
    [setSearchParams]
  );

  return { selectedCategoryName, selectCategory };
};

// 選択中の出場情報または傷病者情報における、選択中のカテゴリについて、項目のリストを取得する（各項目の値は除く）
export const useFields = () => {
  const info = useJianDetailInfo();
  const { selectedCategoryName } = useSelectCatetory();

  const category = info?.categories.find(
    (category) => category.categoryName === selectedCategoryName
  );
  return orderBy(category?.fields, 'fieldOrder').map(({ value, ...field }) => field);
};

export const useLocalFields = () => {
  const { jianId, infoType, infoTypeId } = useParams<{
    jianId: string;
    infoType: 'dispatch-info' | 'patient-info';
    infoTypeId: string;
  }>();
  const localFields = useRecoilValue(localFieldsState);

  if (
    localFields === undefined ||
    localFields.jianId !== jianId ||
    localFields.infoType !== infoType ||
    localFields.infoTypeId !== infoTypeId
  ) {
    return [];
  }

  return localFields.fields;
};

export const useSetLocalFields = () => {
  const { jianId, infoType, infoTypeId } = useParams<{
    jianId: string;
    infoType: 'dispatch-info' | 'patient-info';
    infoTypeId: string;
  }>();
  const setLocalFields = useSetRecoilState(localFieldsState);

  return useCallback(
    (fieldId: string, value: components['schemas']['Value']) => {
      if (jianId && infoType && infoTypeId) {
        setLocalFields((prev) => {
          if (prev === undefined) {
            return {
              jianId,
              infoType,
              infoTypeId,
              fields: [{ fieldId, value }],
            };
          }
          const otherFields = prev.fields.filter((f) => f.fieldId !== fieldId);
          return {
            jianId,
            infoType,
            infoTypeId,
            fields: [...otherFields, { fieldId, value }],
          };
        });
      }
    },
    [jianId, infoType, infoTypeId, setLocalFields]
  );
};

// ローカルの変更をクリアする
export const useClearLocalFields = () => {
  const setLocalFields = useSetRecoilState(localFieldsState);
  return () => setLocalFields(undefined);
};

// 特定の項目の値を取得する
export const useField = (fieldId: string) => {
  const info = useJianDetailInfo();
  const localFields = useLocalFields();

  const field = info?.categories
    .flatMap((category) => category.fields)
    .find((field) => field?.fieldId === fieldId);
  const remoteFieldValue = field?.value;
  const localFieldValue = localFields.find((f) => f.fieldId === fieldId)?.value;

  return { ...field, value: localFieldValue !== undefined ? localFieldValue : remoteFieldValue };
};

// 特定の項目を更新する
export const useSetFieldValue = (fieldId: string) => {
  const info = useJianDetailInfo();
  const setLocalFields = useSetLocalFields();

  const setLocalFieldValue = useCallback(
    (fieldId: string, value: components['schemas']['Value']) => {
      setLocalFields(fieldId, value);
    },
    [setLocalFields]
  );

  const clearUnchoicedChildren = useCallback(
    (fieldId: string, value: components['schemas']['Value']) => {
      const children =
        info?.categories
          .flatMap((category) => category.fields)
          .find((field) => field?.fieldId === fieldId)?.children ?? [];
      for (const child of children) {
        if (child.choiceValue === value) continue;
        for (const childfield of child.children ?? []) {
          // 各childfieldについて、fieldType="SINGLECHOICE"を前提とする
          setLocalFieldValue(childfield.fieldId, defaultValue('SINGLECHOICE'));
          clearUnchoicedChildren(childfield.fieldId, defaultValue('SINGLECHOICE'));
        }
      }
    },
    [info, setLocalFieldValue]
  );

  const setFieldValue = useCallback(
    (value: components['schemas']['Value']) => {
      setLocalFieldValue(fieldId, value);
      clearUnchoicedChildren(fieldId, value);
    },
    [fieldId, setLocalFieldValue, clearUnchoicedChildren]
  );

  return setFieldValue;
};

// 項目をアップロードする
export const useFieldsUploadMutation = () => {
  const queryClient = useQueryClient();
  const { jianId, infoType, infoTypeId } = useParams<{
    jianId: string;
    infoType: 'dispatch-info' | 'patient-info';
    infoTypeId: string;
  }>();
  const info = useJianDetailInfo();
  const fields = info?.categories.flatMap((category) => category.fields).filter(nonNullable) ?? [];
  const localFields = useLocalFields();
  const resetLocalFields = useClearLocalFields();

  const updatedFields = fields.map((field) => {
    const localFieldValue = localFields.find((f) => f.fieldId === field.fieldId)?.value;
    const remoteFieldValue = field.value;
    const fieldValue =
      localFieldValue !== undefined
        ? localFieldValue
        : remoteFieldValue !== undefined
        ? remoteFieldValue
        : defaultValue(field.fieldType);
    return {
      fieldId: field.fieldId,
      value: fieldValue,
    };
  });

  return useMutation({
    mutationFn: async () => {
      if (!jianId || !infoType || !infoTypeId) {
        throw new Error('jianId, infoType and infoTypeId are required');
      }

      const updatePromise =
        infoType === 'patient-info'
          ? updatePatientInfo(jianId, infoTypeId, updatedFields)
          : updateDispathInfo(jianId, infoTypeId, updatedFields);

      toast.promise(updatePromise, {
        loading: '保存中...',
        success: '保存が完了しました。',
        error: '保存に失敗しました。',
      });

      const res = await updatePromise;
      await queryClient.invalidateQueries(['jian', jianId]);
      resetLocalFields();

      return res;
    },
  });
};

// リモートとローカルに差分があるかどうかを確認する
export const useHasChangesToUpload = () => {
  const localFields = useLocalFields();
  const info = useJianDetailInfo();

  if (localFields.length === 0) {
    return false;
  }

  return localFields.some((localField) => {
    const remoteFieldValue = info?.categories
      .flatMap((category) => category.fields)
      .find((field) => field?.fieldId === localField.fieldId)?.value;
    return localField.value !== remoteFieldValue;
  });
};

// 事案詳細画面から他の画面へ遷移する前に、リモートとローカルに差分があれば確認ダイアログを表示する
export const useNavigateFromJianDetail = () => {
  const navigate = useNavigate();
  const hasChangesToUpload = useHasChangesToUpload();
  const resetLocalFields = useClearLocalFields();

  return useCallback(
    (to: To, options?: NavigateOptions) => {
      if (
        !hasChangesToUpload ||
        window.confirm('未保存の変更内容があります。変更内容を破棄してページを移動しますか？')
      ) {
        resetLocalFields();
        navigate(to, options);
      }
    },
    [hasChangesToUpload, navigate, resetLocalFields]
  );
};

// 傷病者の搬送者数をカウントする
export const useTransportedPatient = (jianId: string, patientInfoIds: string[]) => {
  const results = useQueries({
    queries: patientInfoIds.map((patientInfoId) => ({
      queryKey: ['jian', jianId, 'patient-info', patientInfoId],
      queryFn: async () => {
        return await queryPatientInfo(jianId, patientInfoId);
      },
    })),
  });

  const transportedPatientCount =
    results.filter(
      (result) =>
        result.data?.categories.find(
          (category) =>
            category.categoryName === '基本情報' &&
            category.fields?.find((field) => field.fieldName === '不搬送' && field.value !== '該当')
        ) !== undefined
    ).length || 0;

  return { transportedPatientCount };
};
