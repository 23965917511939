import { useQuery } from '@tanstack/react-query';

import { useSelectedYear } from './useYears';
import { datacrudApiClient as client } from '../utils/apiClient';

export const useFieldMaster = () => {
  const selectedYear = useSelectedYear();
  const { data } = useQuery({
    queryKey: ['field-master', selectedYear],
    queryFn: async () => {
      const res = await client.GET('/field-master/{year}', {
        params: {
          path: {
            year: selectedYear ?? '',
          },
        },
      });
      if (res.error) {
        throw new Error(`Failed to GET /field-master \n message: ${res.error.message}`);
      }
      return res.data;
    },
    enabled: !!selectedYear,
  });
  return {
    dispatchInfoCategories:
      data?.fieldMaster.dispatchInfoCategories.sort((a, b) => a.categoryOrder - b.categoryOrder) ??
      [],
    patientInfoCategories:
      data?.fieldMaster.patientInfoCategories.sort((a, b) => a.categoryOrder - b.categoryOrder) ??
      [],
  };
};
