export const getRegion = () => {
  const region = process.env.REACT_APP_DEPLOYMENT_REGION;

  if (region === undefined) {
    throw new Error('Environment variable `REACT_APP_DEPLOYMENT_REGION` is undefined.');
  }

  if (region !== 'Default' && region !== 'Fujisawa') {
    throw new Error('Environment variable `REACT_APP_DEPLOYMENT_REGION` has unexpected value.');
  }

  return region as 'Default' | 'Fujisawa';
};

export const useRegion = () => {
  const region = getRegion();
  return region;
};
