// パスワードに用いることのできる特殊文字一覧
// 参考: https://docs.aws.amazon.com/ja_jp/cognito/latest/developerguide/user-pool-settings-policies.html

const symbols = '^$*.[]{}()?"!@#%&/\\,><\':;|_~`=+-'.split('');

const escapeRequiredSymbols = '^$*.[]{}()?+-\\'.split('');

export const escapedSymbolArray = symbols.map((symbol) =>
  escapeRequiredSymbols.includes(symbol) ? '\\' + symbol : symbol
);

export const checkPassword = (password: string) => {
  if (!/[0-9]/.test(password)) {
    alert('パスワードには数字を1つ以上含めてください');
    return false;
  }
  if (!/[a-z]/.test(password)) {
    alert('パスワードには英小文字を1つ以上含めてください');
    return false;
  }
  if (!/[A-Z]/.test(password)) {
    alert('パスワードには英大文字を1つ以上含めてください');
    return false;
  }
  if (!new RegExp(`[${escapedSymbolArray.join('')}]`).test(password)) {
    alert('パスワードには記号を1つ以上含めてください');
    return false;
  }

  return true;
};
