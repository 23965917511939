import React from 'react';
import { TableHeader } from './TableHeader';
import { TableBody } from './TableBody';
import { TableFooter } from './TableFooter';

export const Table: React.VFC = () => {
  return (
    <div className="w-full border-t-4 border-b-4 border-darkblue_100 text-darkblue_100">
      <div className="overflow-auto max-h-112">
        <table className="w-full relative">
          <TableHeader />
          <TableBody />
        </table>
      </div>
      <TableFooter />
    </div>
  );
};
