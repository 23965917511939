import React, { Suspense } from 'react';
import { Loader } from 'react-feather';
import { Helmet } from 'react-helmet-async';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import { HeaderComponent } from './components/HeaderComponent';
import { NotFound } from './components/NotFound';
import { JianList } from './Pages/JianList/JianList';
import { JianDetail } from './Pages/JianDetail';
import { JianSearch } from './Pages/JianSearch/JianSearch';
import { MasterSetting, MemberMaster, TeamMaster, RoleMaster } from './Pages/MasterSetting';
import { AppJian } from './Pages/AppJian/AppJian';
import { CsvPattern } from './Pages/CsvPattern/CsvPattern';
import { CsvPatternList } from './Pages/CsvPattern/CsvPatternList/CsvPatternList';
import { CsvPatternDetail } from './Pages/CsvPattern/CsvPatternDetail/CsvPatternDetail';
import { Hospital } from './Pages/Hospital/Hospital';
import { HospitalList } from './Pages/Hospital/HospitalList/HospitalList';
import { HospitalDetail } from './Pages/Hospital/HospitalDetail/HospitalDetail';
import { Toaster } from 'react-hot-toast';

const Loading = () => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Loader className="animate-spin" />
    </div>
  );
};

const JianListWrapper = () => {
  return <JianList />;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<HeaderComponent />}>
      <Route index element={<JianListWrapper />} />
      <Route path=":year">
        <Route index element={<JianListWrapper />} />
        <Route path="jian/:jianId/:infoType" element={<JianDetail />}>
          <Route index element={<JianDetail />} />
          <Route path=":infoTypeId" element={<JianDetail />} />
        </Route>
        <Route path="csv-pattern" element={<CsvPattern />}>
          <Route index element={<CsvPatternList />} />
          <Route path=":patternId" element={<CsvPatternDetail />} />
        </Route>
        <Route path="search" element={<JianSearch />} />
        <Route path="app-jians" element={<AppJian />} />
      </Route>
      <Route path="hospital" element={<Hospital />}>
        <Route index element={<HospitalList />} />
        <Route path=":hospitalId" element={<HospitalDetail />} />
      </Route>
      <Route path="setting" element={<MasterSetting />}>
        <Route path="team" element={<TeamMaster />} />
        <Route path="member" element={<MemberMaster />} />
        <Route path="role" element={<RoleMaster />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

export const App = () => {
  return (
    <React.StrictMode>
      <Helmet>
        <title>NOA | Login</title>
      </Helmet>
      <Toaster />
      <Authenticator hideSignUp={true}>
        {() => {
          return (
            <Suspense fallback={<Loading />}>
              <RouterProvider router={router} />
            </Suspense>
          );
        }}
      </Authenticator>
    </React.StrictMode>
  );
};
