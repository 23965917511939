import { VFC } from 'react';
import classNames from 'classnames';

import { CheckBox } from '../../CheckBox';

type JianListCheckboxCellProps = { checked: boolean; onChange: (value: boolean) => void };

export const JianListCheckboxCell: VFC<JianListCheckboxCellProps> = ({ checked, onChange }) => {
  return (
    <td className={classNames('p-0 sticky left-0 bg-darkblue_80 z-0')}>
      <CheckBox checked={checked} onChange={onChange} className="w-16 text-white" />
    </td>
  );
};
