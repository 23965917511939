type Props = { label: string; value: string };

export const ViewText: React.VFC<Props> = ({ label, value }) => {
  return (
    <div className="w-80 flex items-center gap-3">
      <div className="min-w-max text-sm text-darkblue_70 font-bold">{label}</div>
      <div className="text-lg text-darkblue_100">{value}</div>
    </div>
  );
};
