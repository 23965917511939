import { VFC } from 'react';

import { MemberSearchButton } from '../../MemberSearchButton';
import { HospitalSearchButton } from '../../HospitalSearchButton';
import { getRegion } from '../../../../hooks/useRegion';

type InputTextAreaProps = {
  value: string;
  onNewValueSet: (newValue: string) => void;
  fieldId: string;
  disabled?: boolean;
};

export const InputTextArea: VFC<InputTextAreaProps> = ({
  value,
  onNewValueSet,
  fieldId,
  disabled,
}) => {
  const resizeHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + 2 + 'px';
  };
  const fieldIdForMemberSearch = getMemberSearch(fieldId);
  const fieldIdForHospitalSearch = getHospitalSearch(fieldId);

  return (
    <div className="py-1 px-2 flex items-center">
      <textarea
        className={`font-bold ${
          disabled ? 'text-gray-500' : 'text-gray-900'
        } w-full px-3 py-2 text-left rounded bg-transparent border-transparent`}
        value={value}
        onChange={(e) => {
          onNewValueSet(e.target.value);
          resizeHeight(e);
        }}
        disabled={disabled}
      />
      {fieldIdForMemberSearch && (
        <MemberSearchButton fieldIdForAutoComplete={fieldIdForMemberSearch} />
      )}
      {fieldIdForHospitalSearch && (
        <HospitalSearchButton fieldIdForAutoComplete={fieldIdForHospitalSearch} />
      )}
    </div>
  );
};

const getMemberSearch = (fieldId: string) => {
  const region = getRegion();
  if (region === 'Default') {
    switch (fieldId) {
      // 出場情報 出動概要 報告者
      case '0e6a4fcd-2637-478f-a7c5-9614b0596a2e':
        return {
          memberCode: '0e6a4fcd-2637-478f-a7c5-9614b0596a2e', // 報告者コード
          memberName: '4efff949-e018-47c1-8220-f73ce16b08c6', // 報告者氏名
        };

      default:
        return undefined;
    }
  }
  if (region === 'Fujisawa') {
    switch (fieldId) {
      // 出場情報 出動概要 報告者
      case '0e6a4fcd-2637-478f-a7c5-9614b0596a2e':
        return {
          memberCode: '0e6a4fcd-2637-478f-a7c5-9614b0596a2e', // 報告者コード
          memberName: '4efff949-e018-47c1-8220-f73ce16b08c6', // 報告者氏名
        };

      // 出場情報 隊情報 隊長
      case '1f5026fc-6b38-4194-a68f-11f7607e2557':
        return {
          memberCode: '1f5026fc-6b38-4194-a68f-11f7607e2557', // 隊長コード
          memberName: '460d8e80-29d7-4326-a6c8-087476bdc68f', // 隊長氏名
          licenseStatus: 'bec731fc-138a-4bb5-82f3-b8222c5c5a2c', // 隊長資格認定状況
          emtClass: '5a0e88fb-3bef-4a6c-8f84-f48d0565b301', // 隊長救急救命士認定区分
        };

      // 出場情報 隊情報 隊員1
      case '00f6eb54-7e5a-4b73-92d4-c1efff166ce9':
        return {
          memberCode: '00f6eb54-7e5a-4b73-92d4-c1efff166ce9', // 隊員1コード
          memberName: '61b1265c-fefd-4283-bb8a-d6d17f5b9597', // 隊員1氏名
          licenseStatus: '52fa44e7-840b-417a-bb69-853678b16183', // 隊員1資格認定状況
          emtClass: 'fb82782d-94bd-4ebb-9e0d-f9bece19209b', // 隊員1救急救命士認定区分
        };

      // 出場情報 隊情報 隊員2
      case 'b807be4a-3b66-44c3-8b42-ae6b7d8d819f':
        return {
          memberCode: 'b807be4a-3b66-44c3-8b42-ae6b7d8d819f', // 隊員2コード
          memberName: 'eb3d9b9e-5bf6-40f0-b32a-5a5a20581c5e', // 隊員2氏名
          licenseStatus: 'da89de6e-f029-48b3-ada2-e760b1b379ea', // 隊員2資格認定状況
          emtClass: '8af9f5b6-48f2-4c07-8216-e1731d96fd50', // 隊員2救急救命士認定区分
        };

      // 出場情報 隊情報 機関員
      case 'aba49058-ed75-429b-9757-a5af3a715448':
        return {
          memberCode: 'aba49058-ed75-429b-9757-a5af3a715448', // 機関員コード
          memberName: '80d0f4c6-5a5f-4b38-85a2-bfc992c4877f', // 機関員氏名
          licenseStatus: 'f96855c7-14e5-4945-ab45-d7a125b123ca', // 機関員資格認定状況
          emtClass: 'c079cd66-8e4a-4d23-892d-4818c36337a0', // 機関員救急救命士認定区分
        };

      // 傷病者情報 市民処置 口頭指導員
      case 'd5ef9ed8-8c73-4ddc-bb57-c35eefabd819':
        return {
          code: 'd5ef9ed8-8c73-4ddc-bb57-c35eefabd819', // 口頭指導員コード
          memberName: '870df4f6-3dda-4ffa-b8ba-153fe4e98ab2', // 口頭指導員氏名
        };

      // 傷病者情報 特定行為 除細動1実施者
      case 'aa6e1e6a-39b1-48a2-b55b-5ab8ccbe6985':
        return {
          memberName: 'aa6e1e6a-39b1-48a2-b55b-5ab8ccbe6985', // 除細動1実施者氏名
        };

      // 傷病者情報 特定行為 除細動2実施者
      case '8750a904-aa0c-4a66-886c-16a67b3e9dab':
        return {
          memberName: '8750a904-aa0c-4a66-886c-16a67b3e9dab', // 除細動2実施者氏名
        };

      // 傷病者情報 特定行為 除細動3実施者
      case '8d8c6330-2cad-40ac-baa1-8261cb75c587':
        return {
          memberName: '8d8c6330-2cad-40ac-baa1-8261cb75c587', // 除細動3実施者氏名
        };

      // 傷病者情報 特定行為 除細動4実施者
      case '8bcd2fbd-b560-4d89-8891-82c6fbe75329':
        return {
          memberName: '8bcd2fbd-b560-4d89-8891-82c6fbe75329', // 除細動4実施者氏名
        };

      // 傷病者情報 特定行為 除細動5実施者
      case 'aec0c456-91eb-44c6-81cf-e6f568c44620':
        return {
          memberName: 'aec0c456-91eb-44c6-81cf-e6f568c44620', // 除細動5実施者氏名
        };

      // 傷病者情報 特定行為 除細動6実施者
      case 'd59fd9ef-c31e-446f-a93a-d84d4bfeab24':
        return {
          memberName: 'd59fd9ef-c31e-446f-a93a-d84d4bfeab24', // 除細動6実施者氏名
        };

      // 傷病書情報 特定行為 気道確保実施者
      case 'd42b554a-2c83-419d-a703-8d2b67af6516':
        return {
          memberCode: 'd42b554a-2c83-419d-a703-8d2b67af6516', // 気道確保実施者コード
          memberName: '38968319-0608-4be6-a636-faddbd36e7c6', // 気道確保実施者氏名
        };

      // 傷病書情報 特定行為 薬剤投与1実施者
      case '785ee942-005d-4a8c-9e55-54bdca2a6b33':
        return {
          memberCode: '785ee942-005d-4a8c-9e55-54bdca2a6b33', // 薬剤投与1実施者コード
          memberName: 'ef33e53c-f125-4292-ac71-759281e6063c', // 薬剤投与1実施者氏名
        };

      // 傷病書情報 特定行為 薬剤投与2実施者
      case 'ac3134f4-e1c5-4cc7-b4a9-d98c7e218db7':
        return {
          memberCode: 'ac3134f4-e1c5-4cc7-b4a9-d98c7e218db7', // 薬剤投与2実施者コード
          memberName: '1331fea9-f1d3-4d7b-9f48-7a98d6d64302', // 薬剤投与2実施者氏名
        };

      // 傷病書情報 特定行為 薬剤投与3実施者
      case 'cf2c63f6-7c28-41bd-86b5-d41b3f11d91d':
        return {
          memberCode: 'cf2c63f6-7c28-41bd-86b5-d41b3f11d91d', // 薬剤投与3実施者コード
          memberName: 'cbb466b7-17e2-4607-b4ff-66b5db1abea1', // 薬剤投与3実施者氏名
        };

      // 傷病書情報 特定行為 薬剤投与4実施者
      case 'a3d58451-717c-403a-8efc-a47eb74d0c24':
        return {
          memberCode: 'a3d58451-717c-403a-8efc-a47eb74d0c24', // 薬剤投与4実施者コード
          memberName: '5d7f9378-effe-4393-bdd1-5475eb8a2227', // 薬剤投与4実施者氏名
        };

      // 傷病書情報 特定行為 薬剤投与5実施者
      case 'aeee20c2-ab53-42f0-85d1-4edde965172b':
        return {
          memberCode: 'aeee20c2-ab53-42f0-85d1-4edde965172b', // 薬剤投与5実施者コード
          memberName: '69bf7078-621a-4fe2-a99d-62e7b30b1dc4', // 薬剤投与5実施者氏名
        };

      default:
        return undefined;
    }
  }
};

const getHospitalSearch = (fieldId: string) => {
  const region = getRegion();
  if (region === 'Default') {
    switch (fieldId) {
      // 出場情報 出動概要 要請機関
      case '39c430d5-d1e2-4b0d-b012-af21aaddd67e':
        return {
          code: '39c430d5-d1e2-4b0d-b012-af21aaddd67e', // 要請機関：コード
          hospitalEmbodimentType: 'edffd65e-e6e4-4641-a49b-aaaeaf2e1291', // 要請機関：形態
          hospitalEmergencyType: 'afb002b4-c8ac-4eb1-b06c-9490ec6de615', // 要請機関：告示
          hospitalEstablishmentType: '97895b95-2bf2-4c64-8edd-0d53a66d8a7a', // 要請機関：設立
          hospitalName: 'c184fef9-5a08-4cdf-aecb-3963831ec923', // 要請機関名
        };
      default:
        return undefined;
    }
  }
  if (region === 'Fujisawa') {
    switch (fieldId) {
      // 出場情報 出動概要 転院元医療機関
      case '9b30f5df-2c65-40be-821b-90952da0f293':
        return {
          code: '9b30f5df-2c65-40be-821b-90952da0f293', // 転院元医療機関コード
          hospitalName: '8d53578f-e543-4782-9bc8-dda864fa2791', // 転院元医療機関名
          hospitalEmbodimentType: 'cb24025a-a1bc-4084-90e3-dfa9be59b5a1', // 転院元医療機関実施形態
        };

      // 傷病者情報 基本情報 収容機関
      case '20936d4a-5035-4b04-a4d6-09d91f1bb892':
        return {
          code: '20936d4a-5035-4b04-a4d6-09d91f1bb892', // 収容機関コード
          hospitalName: 'c2650e48-e885-4ee2-9309-1eec08d9e9dc', // 収容機関名
          jurisdiction: '599ac010-800d-4214-b7ca-825fa898258c', // 病院管内管外区分
          hospitalEmbodimentType: '59262b07-c27e-49a0-a52d-ef3d18cfda2d', // 収容機関実施形態
        };

      // 傷病者情報 病歴 通院病院
      case 'cb87c59d-76e5-41c5-b282-51e44054fe76':
        return {
          hospitalName: 'cb87c59d-76e5-41c5-b282-51e44054fe76', // 通院病院名
          hospitalEmbodimentType: 'a55ad441-48e6-444a-856f-8d0d8c86b083', // 通院病院実施形態
        };

      // 傷病者情報 転送 転送病院1
      case 'eeeae236-6968-42cf-928f-cb06d56c138c':
        return {
          hospitalName: 'eeeae236-6968-42cf-928f-cb06d56c138c', // 転送病院1医療機関名
          hospitalEmergencyType: 'dbdc3fb3-fd0c-4e0b-b935-713b380d7116', // 転送病院1告示
          hospitalEmbodimentType: '546b5e48-2c6d-408b-b845-071d7d32409b', // 転送病院1実施形態
        };

      // 傷病者情報 転送 転送病院2
      case '12ed3f3e-bfce-4987-9260-6a018406c842':
        return {
          hospitalName: '12ed3f3e-bfce-4987-9260-6a018406c842', // 転送病院2医療機関名
          hospitalEmergencyType: '5fe62839-ba92-4bed-805f-b6bdc93edb52', // 転送病院2告示
          hospitalEmbodimentType: '574fc789-73d2-4ec8-a53e-be0c88f70f9d', // 転送病院2実施形態
        };

      // 傷病者情報 転送 転送病院3
      case '8d5c9d2b-d419-4323-a247-16bce249af50':
        return {
          hospitalName: '8d5c9d2b-d419-4323-a247-16bce249af50', // 転送病院3医療機関名
          hospitalEmergencyType: 'e6b96207-aca1-4c4d-81a7-6528666d95e7', // 転送病院3告示
          hospitalEmbodimentType: '3fc5d7eb-2d70-4049-bd80-9e1853404e19', // 転送病院3実施形態
        };

      // 傷病者情報 転送 転送病院4
      case '82efe943-6e3f-4008-a29d-de2b238c8532':
        return {
          hospitalName: '82efe943-6e3f-4008-a29d-de2b238c8532', // 転送病院4医療機関名
          hospitalEmergencyType: '01538d81-24be-4a34-ab29-e03f7eff9614', // 転送病院4告示
          hospitalEmbodimentType: '8213ac46-f57d-4d3a-95d4-452f5a9fa79a', // 転送病院4実施形態
        };

      // 傷病者情報 転送 転送病院5
      case '61cd236d-f908-47a4-b617-bc601d34be50':
        return {
          hospitalName: '61cd236d-f908-47a4-b617-bc601d34be50', // 転送病院5医療機関名
          hospitalEmergencyType: '4f15b82f-e38b-4d10-ae92-9a1dc65e0be1', // 転送病院5告示
          hospitalEmbodimentType: 'cd1f167c-42b2-451a-899f-934c86429261', // 転送病院5実施形態
        };

      // 傷病者情報 転送 転送病院6
      case '83a81842-b50c-40a1-a55f-2857d96e396e':
        return {
          hospitalName: '83a81842-b50c-40a1-a55f-2857d96e396e', // 転送病院6医療機関名
          hospitalEmergencyType: '56e45cf2-a262-4241-88c9-c0f598d4971d', // 転送病院6告示
          hospitalEmbodimentType: '20673396-a584-47cd-a78f-cde1bd6087f9', // 転送病院6実施形態
        };

      // 傷病者情報 病院要請 要請1
      case 'cbcf6e3d-5b8e-4014-9c34-ee7a46ca3d79':
        return {
          code: 'cbcf6e3d-5b8e-4014-9c34-ee7a46ca3d79', // 要請1医療機関コード
          hospitalName: '4f3152ee-3a42-4981-a54b-6c0963e47bca', // 要請1医療機関名
          hospitalEmbodimentType: '34336796-2c79-4f20-bda0-51fd7f20393c', // 要請1医療機関実施形態
        };

      // 傷病者情報 病院要請 要請2
      case 'bbd854e6-fb0b-4778-bec3-d0dc7f2634e7':
        return {
          code: 'bbd854e6-fb0b-4778-bec3-d0dc7f2634e7', // 要請2医療機関コード
          hospitalName: '8d723b5a-8255-437c-8723-68f9b5dda945', // 要請2医療機関名
          hospitalEmbodimentType: 'fb3cde41-a367-4896-9b62-a2679ef65974', // 要請2医療機関実施形態
        };

      // 傷病者情報 病院要請 要請3
      case 'a26826f2-a1da-48d3-bd16-f043690fbfc6':
        return {
          code: 'a26826f2-a1da-48d3-bd16-f043690fbfc6', // 要請3医療機関コード
          hospitalName: 'f0a86e66-e0e3-40cc-9b79-cf12ee6256ea', // 要請3医療機関名
          hospitalEmbodimentType: 'da244f86-692e-45f3-bf54-028e23799aa2', // 要請3医療機関実施形態
        };

      // 傷病者情報 病院要請 要請4
      case '84da833e-94a4-479d-8332-1d0e85889dfe':
        return {
          code: '84da833e-94a4-479d-8332-1d0e85889dfe', // 要請4医療機関コード
          hospitalName: '8589f82e-8de2-40bb-b36d-87e720eb297b', // 要請4医療機関名
          hospitalEmbodimentType: '08267459-4273-457e-8137-da72e6b2b30d', // 要請4医療機関実施形態
        };

      // 傷病者情報 病院要請 要請5
      case 'f81f99ee-ac4e-42c7-b4d4-3c90b34f9f33':
        return {
          code: 'f81f99ee-ac4e-42c7-b4d4-3c90b34f9f33', // 要請5医療機関コード
          hospitalName: 'c673f7dd-f3bc-477f-9c20-4d52c47cd0a2', // 要請5医療機関名
          hospitalEmbodimentType: 'f4a1a67d-0092-4ba9-96b6-f8113f5d531a', // 要請5医療機関実施形態
        };

      // 傷病者情報 特定行為 指示医療機関
      case '6bb4dce5-724f-4ac6-a07e-80a1c219c945':
        return {
          code: '6bb4dce5-724f-4ac6-a07e-80a1c219c945', // 指示医療機関コード
          hospitalName: '7a9ecbca-2cd7-4198-a5c2-577ff6dbae8f', // 指示医療機関名
          hospitalEmbodimentType: 'cb046f7b-dbef-4f38-8585-f8e50217a74a', // 指示医療機関実施形態
        };

      default:
        return undefined;
    }
  }
};
