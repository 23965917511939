import { VFC } from 'react';

import { formatJapaneseDateString, formatJapaneseTimeString } from '../../../utils/dateUtils';

type Props = {
  dateString: string;
  onClick: () => void;
};

export const JianListDateCell: VFC<Props> = ({ dateString, onClick }) => {
  const parsedString = Date.parse(dateString);
  const date = new Date(parsedString);
  return (
    <td className="p-0 sticky left-16 bg-darkblue_50 break-all">
      <button className="w-36 z-10" onClick={onClick}>
        <div className="text-base text-white">
          {!Number.isNaN(parsedString) ? (
            <>
              <p className="text-center">{formatJapaneseDateString(date)}</p>
              <p className="text-center">{formatJapaneseTimeString(date, 'second')}</p>
            </>
          ) : (
            <p className="text-center">----</p>
          )}
        </div>
      </button>
    </td>
  );
};
