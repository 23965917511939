import React from 'react';
import { NavLink } from 'react-router-dom';

export const Navigation: React.VFC = () => {
  return (
    <nav className="w-60 h-fit bg-darkblue_10 rounded p-5">
      <ul className="space-y-2">
        <NavItem to="team" text="救急隊" />
        <NavItem to="member" text="隊員アカウント" />
        <NavItem to="role" text="権限ロール" />
      </ul>
    </nav>
  );
};

const NavItem: React.VFC<{ to: string; text: string }> = ({ to, text }) => {
  const commonClassNames = 'block w-48 p-2 rounded text-center font-bold text-lg ';
  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive
            ? commonClassNames + 'bg-darkblue_100 hover:bg-darkblue_70 text-white'
            : commonClassNames + 'bg-darkblue_20 hover:bg-darkblue_30 text-darkblue_100'
        }
      >
        {text}
      </NavLink>
    </li>
  );
};
