import { VFC } from 'react';

type InputSingleSelectProps = {
  options: string[];
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
};

export const InputSingleSelect: VFC<InputSingleSelectProps> = ({
  options,
  value,
  onChange,
  disabled,
}) => {
  return (
    <div className="py-1 px-2">
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`font-bold ${
          disabled ? 'text-gray-600' : 'text-gray-900'
        } w-full px-3 py-2 text-left rounded bg-transparent border-transparent`}
        disabled={disabled}
      >
        <option key="__unselected__" value="">
          --未選択--
        </option>
        {options.map((option) => (
          <option className="font-bold" key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
