const formatJapaneseMonthString = (year: number, month: number) => {
  const date = new Date(year, month - 1);
  const format = Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long' }).format(date);
  if (format.split('/').length === 3) {
    const [year, month] = format.split('/');
    return `${year}年${month}月`;
  }
  return `----年--月`;
};

const formatJapaneseDateString = (date: Date) => {
  const format = Intl.DateTimeFormat('ja-JP-u-ca-japanese', { era: 'long' }).format(date);
  if (format.split('/').length === 3) {
    const year = format.split('/')[0];
    const month = format.split('/')[1];
    const day = format.split('/')[2];
    return `${year}年${month}月${day}日`;
  }
  return `----年--月--日`;
};

const formatJapaneseTimeString = (date: Date, presicion: 'minute' | 'second') => {
  const hour = ('00' + date.getHours()).slice(-2);
  const minute = ('00' + date.getMinutes()).slice(-2);
  if (presicion === 'minute') return `${hour}:${minute}`;
  const second = ('00' + date.getSeconds()).slice(-2);
  return `${hour}:${minute}:${second}`;
};

const formatJapaneseDateTimeString = (
  date: Date,
  presicion: 'day' | 'minute' | 'second' = 'minute'
) => {
  const dateStr = formatJapaneseDateString(date);
  if (presicion === 'day') return dateStr;
  return `${dateStr} ${formatJapaneseTimeString(date, presicion)}`;
};

export {
  formatJapaneseMonthString,
  formatJapaneseDateString,
  formatJapaneseTimeString,
  formatJapaneseDateTimeString,
};
