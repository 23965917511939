import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { useMe } from '../useMe';
import { sleep } from '../../utils/sleep';
import { datacrudApiClient as client } from '../../utils/apiClient';

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const postForRequestId = async (fireDepartmentId: string, jianIds: string[]): Promise<string> => {
  const { data, error } = await client.POST('/oa-csv/request', {
    body: {
      fire_department_id: fireDepartmentId,
      target_jian_ids: jianIds,
    },
  });

  if (error) {
    throw new Error(`Failed to POST /oa-csv/request \n message: ${error.message}`);
  }

  if (data.request_id === '') {
    throw new Error('Failed to get request_id');
  }

  return data.request_id;
};

const getDownloadUrl = async (
  fireDepartmentId: string,
  requestId: string,
  retry: number = 10,
  interval: number = 1000
): Promise<string> => {
  for (let i = 0; i < retry; i++) {
    await sleep(interval);
    const { data, error } = await client.GET('/oa-csv/{fireDepartmentId}/{requestId}', {
      params: {
        path: {
          fireDepartmentId,
          requestId,
        },
      },
    });

    if (error) {
      throw new Error(
        `Failed to GET /oa-csv/${fireDepartmentId}/${requestId} \n message: ${error.message}`
      );
    }

    if (data.status === 'generated' && data.presigned_url !== '') {
      return data.presigned_url;
    }
  }

  throw new Error('Failed to get download url (timeout)');
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useOACsvDownload = () => {
  const me = useMe();
  return useCallback(
    async (jianIds: string[]) => {
      const fireDepartmentId = me?.fireDepartmentId;

      if (fireDepartmentId == null) {
        toast.error('認証情報が取得できませんでした。ログインし直してください。');
        console.error('Failed to get fireDepartmentId');
        return;
      }

      const toastId = toast.loading('ダウンロード中...');
      try {
        const requestId = await postForRequestId(fireDepartmentId, jianIds);
        const downloadUrl = await getDownloadUrl(fireDepartmentId, requestId);
        toast.success('ダウンロードが完了しました', { id: toastId });
        window.location.href = downloadUrl;
      } catch (e) {
        toast.error('ダウンロードに失敗しました', { id: toastId });
        console.error(e);
      }
    },
    [me]
  );
};
