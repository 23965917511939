import { useParams } from 'react-router-dom';

import { datacrudApiClient as client } from '../utils/apiClient';
import { useQuery } from '@tanstack/react-query';

const getYears = async () => {
  const { data, error } = await client.GET('/design-years');
  if (error) {
    throw new Error(`Failed to GET /design-years \n message: ${error.message}`);
  }
  return data;
};

export const useYears = () => {
  const { data } = useQuery({
    queryKey: ['design-years'],
    queryFn: getYears,
  });
  const years = data?.map((item) => item.year) ?? [];
  return years;
};

export const useSelectedYear = () => {
  const { year } = useParams();
  return year;
};

export const useSelectedOrCurrentYear = () => {
  const { year } = useParams();
  const currentYear = new Date().getFullYear().toString();
  return year ?? currentYear;
};
