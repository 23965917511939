import { Link, useLocation, useParams } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Menu } from '@headlessui/react';
import { useYears } from '../../../hooks/useYears';
import classNames from 'classnames';

export const YearButton = () => {
  const years = useYears();
  const { year: selectedYear } = useParams();
  const disabled = !selectedYear;
  const location = useLocation();

  const replaceYearPath = (year: string) => {
    const path = location.pathname.split('/');
    const params = location.search;
    path[1] = year;
    return path.join('/') + params;
  };

  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={classNames(
          'flex gap-2 px-2 py-1 rounded  text-darkblue_100 shadow border-2 border-white bg-white',
          {
            'bg-disabled-dark border-disabled-dark text-disabled-dark': disabled,
          },
          { 'hover:bg-darkblue_10': !disabled }
        )}
        disabled={disabled}
      >
        <Icon.Calendar
          className={classNames('self-center', { 'hover:animate-spin': !disabled })}
          size={18}
        />
        {disabled ? '---- 年' : `${selectedYear}年`}
      </Menu.Button>
      <Menu.Items className="absolute flex flex-col items-start gap-2 mt-2 right-0 p-2 rounded border-2 border-darkblue_70 bg-white text-darkblue_100">
        {years.map((year) => (
          <Menu.Item key={year}>
            {year === selectedYear ? (
              <div className="text-gray-400">{year}年</div>
            ) : (
              <Link to={replaceYearPath(year)} className="hover:underline">
                {year}年
              </Link>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};
