// 隊員アカウント管理のロジックを担うHooks
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { memberApiClient } from '../utils/apiClient';
import { components } from '../schema/member-management';

// -----------------------------------------------------------------------------
// CRUD Operation Functions
// -----------------------------------------------------------------------------
const getMembers = async () => {
  const res = await memberApiClient.GET('/members');
  if (res.error) {
    throw new Error(`Failed to GET /members \n message: ${res.error.message}`);
  }
  return res.data;
};

const createMember = async (body: components['schemas']['MemberCreateRequest']) => {
  const res = await memberApiClient.POST('/members', {
    body,
  });
  if (res.error) {
    throw new Error(`Failed to POST /members \n message: ${res.error.message}`);
  }
  return res.data;
};

const updateMember = async (
  memberId: string,
  body: components['schemas']['MemberUpdateRequest']
) => {
  const res = await memberApiClient.PATCH(`/members/{memberId}`, {
    params: { path: { memberId } },
    body,
  });
  if (res.error) {
    throw new Error(`Failed to PATCH /members/{memberId} \n message: ${res.error.message}`);
  }
  return res.data;
};

const updateMemberPassword = async (memberId: string, password: string) => {
  const res = await memberApiClient.PUT(`/members/{memberId}/password`, {
    params: { path: { memberId } },
    body: { password },
  });
  if (res.error) {
    throw new Error(`Failed to PUT /members/{memberId}/password \n message: ${res.error.message}`);
  }
  return res.data;
};

const deleteMember = async (memberId: string) => {
  const res = await memberApiClient.DELETE(`/members/{memberId}`, {
    params: {
      path: { memberId },
    },
  });
  if (res.error) {
    throw new Error(`Failed to DELETE /members/{memberId} \n message: ${res.error.message}`);
  }
  return res.data;
};

// -----------------------------------------------------------------------------
// Hooks
// -----------------------------------------------------------------------------
export const useMembers = () => {
  const { data } = useQuery({
    queryKey: ['members'],
    queryFn: getMembers,
  });

  return data?.members ?? [];
};

export const useCreateMember = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (props: components['schemas']['MemberCreateRequest']) => {
      const promise = createMember(props);
      toast.promise(promise, {
        loading: '新しいアカウントを作成しています...',
        success: '新しいアカウントを作成しました',
        error: '新しいアカウントの作成に失敗しました',
      });
      const res = await promise;
      queryClient.invalidateQueries(['members']);
      return res;
    },
  });
  return {
    isCreating: mutation.isLoading,
    createMember: mutation.mutateAsync,
  };
};

export const useUpdateMember = () => {
  const queryClient = useQueryClient();
  const mutaition = useMutation({
    mutationFn: async ({
      memberId,
      ...props
    }: { memberId: string } & components['schemas']['MemberUpdateRequest']) => {
      const promise = updateMember(memberId, props);
      toast.promise(promise, {
        loading: 'アカウント情報を更新しています...',
        success: 'アカウント情報を更新しました',
        error: 'アカウント情報の更新に失敗しました',
      });
      const res = await promise;
      queryClient.invalidateQueries(['members']);
      return res;
    },
  });
  return {
    isUpdating: mutaition.isLoading,
    updateMember: mutaition.mutateAsync,
  };
};

export const useChangeMemberPassword = () => {
  const mutation = useMutation({
    mutationFn: async (props: { memberId: string; password: string }) => {
      const promise = updateMemberPassword(props.memberId, props.password);
      toast.promise(promise, {
        loading: 'パスワードを変更しています...',
        success: 'パスワードを変更しました',
        error: 'パスワードの変更に失敗しました',
      });
      return await promise;
    },
  });
  return {
    isChangingPassword: mutation.isLoading,
    changePassword: mutation.mutateAsync,
  };
};

export const useDeleteMember = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (memberId: string) => {
      const promise = deleteMember(memberId);
      toast.promise(promise, {
        loading: 'アカウントを削除しています...',
        success: 'アカウントを削除しました',
        error: 'アカウントの削除に失敗しました',
      });
      const res = await promise;
      await queryClient.invalidateQueries(['members']);
      return res;
    },
  });
  return {
    isDeleting: mutation.isLoading,
    deleteMember: mutation.mutateAsync,
  };
};

export const useRefreshMembers = () => {
  const queryClient = useQueryClient();
  return () => queryClient.resetQueries(['members']);
};
