import React from 'react';

import { LabeledCheckbox } from '../../../../components/LabeledCheckbox';
import { useFieldMaster } from '../../../../hooks/useFieldMaster';

export const FieldList: React.VFC<{
  pattern: string[];
  selectedCategory: string;
  onToggleField: (fieldId: string) => void;
}> = ({ pattern, selectedCategory, onToggleField }) => {
  const fieldMaster = useFieldMaster();
  return (
    <ul className="p-4 space-y-2">
      {[...fieldMaster.dispatchInfoCategories, ...fieldMaster.patientInfoCategories]
        .find((category) => category.categoryName === selectedCategory)
        ?.fields.map((field) => (
          <li key={field.fieldId}>
            <LabeledCheckbox
              label={field.fieldName}
              checked={pattern.includes(field.fieldId)}
              fontsize="base"
              onChange={() => onToggleField(field.fieldId)}
            />
          </li>
        ))}
    </ul>
  );
};
