import React, { forwardRef } from 'react';

export type InputNumberProps = Omit<React.ComponentPropsWithRef<'input'>, 'type' | 'onWheel'>;

// マウスホイールでの値変更を無効化する
const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(({ ...props }, ref) => {
  const onWheelHandler = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
    e.stopPropagation();
  };

  return <input type="number" ref={ref} onWheel={onWheelHandler} {...props} />;
});
InputNumber.displayName = 'InputNumber';

export default InputNumber;
