import { useMe } from '../../../hooks/useMe';

export const UserNameDisplay = () => {
  const me = useMe();
  return (
    <div className="space-y-1">
      <div className="text-xs text-white">{me?.teamName}</div>
      <div className="font-bold text-sm text-white" data-testid="userName">
        {me?.memberName}
      </div>
    </div>
  );
};
