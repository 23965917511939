import { CheckBox } from '../../../components/CheckBox';

type Props = {
  label: string;
  values: string[];
  options: { value: string; label: string }[];
  onChangeValues: (values: string[]) => void;
};

export const CheckboxList: React.VFC<Props> = ({ label, values, options, onChangeValues }) => {
  return (
    <div className="w-min-80 flex items-center gap-3">
      <div className="text-sm text-darkblue_70 font-bold">{label}</div>
      <ul className="flex flex-wrap gap-3">
        {options.map((option) => (
          <li key={option.value}>
            <label className="flex items-center">
              <CheckBox
                checked={values.includes(option.value)}
                onChange={(checked) => {
                  const filtered = values.filter((value) => value !== option.value);
                  if (checked) {
                    onChangeValues([...filtered, option.value]);
                  } else {
                    onChangeValues(filtered);
                  }
                }}
              />
              <div className="w-fit text-darkblue_100 cursor-pointer">{option.label}</div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};
