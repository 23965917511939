import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { FieldList } from './FieldList';
import { ErrorFallBack } from '../../components/ErrorFallBack';
import { CategoryList } from './CategoryList';
import { MainActionPanel } from './MainActionPanel';
import { MoveToJianListButton } from '../../components/MoveToJianListButton';
import { SubActionPanel } from './SubActionPanel';
import { useParams } from 'react-router-dom';

const Contents = () => {
  const { infoType, infoTypeId } = useParams<{ infoType: string; infoTypeId: string }>();
  return infoType === 'patient-info' && !infoTypeId ? (
    <div className="container mx-auto">
      <p className="text-base text-darkblue_100">傷病者を選択もしくは追加をしてください</p>
    </div>
  ) : (
    <div className="container mx-auto flex flex-row gap-2">
      <div className="basis-1/4">
        <ErrorBoundary FallbackComponent={ErrorFallBack}>
          <Suspense fallback={<div>Loading...</div>}>
            <CategoryList />
          </Suspense>
        </ErrorBoundary>
      </div>
      <div className="basis-3/4">
        <ErrorBoundary FallbackComponent={ErrorFallBack}>
          <Suspense fallback={<div>Loading...</div>}>
            <FieldList />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export const JianDetail = () => {
  return (
    <Suspense fallback={'Loading...'}>
      <div className="w-full h-full flex flex-col gap-2 overflow-hidden">
        <MoveToJianListButton />
        <MainActionPanel />
        <SubActionPanel />
        <Contents />
      </div>
    </Suspense>
  );
};
