import { VFC, Suspense } from 'react';
import { SearchForm } from './SearchForm';
import { SearchResult } from './SearchResult';
import { MoveToJianListButton } from '../../components/MoveToJianListButton';
export const JianSearch: VFC = () => {
  return (
    <div className="max-h-[calc(100vh-8rem)] w-full flex gap-8 px-10">
      <MoveToJianListButton />
      <SearchForm />
      <div className="flex flex-col flex-1 overflow-x-hidden">
        <h2 className="text-darkblue_100 font-bold text-2xl mb-4">検索結果</h2>
        <Suspense fallback={<div>Loading...</div>}>
          <SearchResult />
        </Suspense>
      </div>
    </div>
  );
};
