import React from 'react';

import { RoleItem } from './RoleItem';
import { useRoles } from '../../../hooks/useRoles';

export const RoleList: React.VFC = () => {
  const roles = useRoles();

  return (
    <ul className="w-full space-y-2">
      {roles.map((role) => (
        <RoleItem key={role.roleId} {...role} />
      ))}
    </ul>
  );
};
