// 隊員のライセンス情報を取得するためのフック
import { useQuery } from '@tanstack/react-query';

import { memberApiClient } from '../utils/apiClient';

export const useLicenses = () => {
  const { data } = useQuery({
    queryKey: ['licenses'],
    queryFn: async () => {
      const res = await memberApiClient.GET('/licenses');
      if (res.error) {
        throw new Error(`Failed to GET /licenses \n message: ${res.error.message}`);
      }
      return res.data;
    },
  });
  return data?.licenses ?? [];
};
