import React from 'react';

import { useHospitalList } from '../../../../hooks/useHospital';

export const TableFooter: React.VFC = () => {
  const { page, isLastPage, incrementPage, decrementPage } = useHospitalList();
  return (
    <div className="px-3 py-2 border-t-2 border-darkblue_100">
      <div className="flex gap-2 justify-between">
        <div className="text-lg font-bold">
          {page * 10 + 1}件目から{!isLastPage ? `${(page + 1) * 10}件目` : ''}を表示中
        </div>
        <div className="flex gap-6">
          {page > 0 && (
            <button className="text-lg font-bold" onClick={() => decrementPage()}>
              前の10件
            </button>
          )}
          {!isLastPage && (
            <button className="text-lg font-bold" onClick={() => incrementPage()}>
              次の10件
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
