import { useQuery, useQueryClient } from '@tanstack/react-query';
import { memberApiClient } from '../utils/apiClient';

export const useMe = () => {
  const { data } = useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      const res = await memberApiClient.GET('/me');
      if (res.error) {
        throw new Error(`Failed to GET /me \n message: ${res.error.message}`);
      }
      return res.data;
    },
  });
  return data;
};

export const useRefreshMe = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['me']);
  };
};
