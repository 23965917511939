import React from 'react';

type Props = {
  text: string;
};

export const LicenseBadge: React.VFC<Props> = ({ text }) => {
  return (
    <span className="w-fit bg-darkblue_10 border border-darkblue_50 text-darkblue_100 text-xs px-1 py-px rounded-sm">
      {text}
    </span>
  );
};
