import { Suspense, useEffect, VFC } from 'react';

import { JianListActionPanel } from './JianListActionPanel';
import { JianListTable } from '../../components/JianListTable';
import { useJianList } from '../../hooks/useJianList';
import { useNavigate, useParams } from 'react-router-dom';

export const JianList: VFC = () => {
  const { year } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!year) {
      const currentYear = new Date().getFullYear();
      navigate(`/${currentYear}`);
    }
  }, [year, navigate]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <JianListActionPanel />
      <div className="container mx-auto overflow-hidden mb-16">
        <JianListContent />
      </div>
    </Suspense>
  );
};

const JianListContent: VFC = () => {
  const { jianList, page, limit, isLastPage, incrementPage, decrementPage } = useJianList();
  const jians =
    jianList?.items.map(({ jianId, dispatchInfoId, summaryFields }) => ({
      jianId,
      infoType: 'dispatch-info' as const,
      infoTypeId: dispatchInfoId,
      fields: summaryFields,
    })) ?? [];
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <JianListTable jians={jians} className="max-h-[calc(100vh-18rem)]" />
      </Suspense>
      <div className="h-10 bg-darkblue_100 flex flex-row justify-between items-center text-xl font-bold px-2 text-white">
        <div>{`${page * limit + 1}件目から${
          isLastPage ? '' : `${(page + 1) * limit}件目`
        }を表示中`}</div>
        <div className="flex flex-row gap-4">
          {page > 0 && (
            <button
              onClick={() => {
                decrementPage();
              }}
            >
              前の{limit}件
            </button>
          )}
          {!isLastPage && (
            <button
              onClick={() => {
                incrementPage();
              }}
            >
              次の{limit}件
            </button>
          )}
        </div>
      </div>
    </>
  );
};
