import React from 'react';

export const Select: React.VFC<React.ComponentPropsWithoutRef<'select'>> = ({
  className,
  ...props
}) => {
  return (
    <select
      className={
        'font-bold rounded border-darkblue_100 border-2 cursor-pointer py-1 max-w-sm ' + className
      }
      {...props}
    />
  );
};
